import React, { useState } from "react";
import Masonry from "react-masonry-css";
import styles from "../styles/Gallery.module.css";

function Gallery() {

//Gallery images
  const images = [
    {
      id: 1,
      src: "/images/book-pics/book-pic (1).jpg",
      caption: "Hardcover Sleeve- Portrait (Right)",
    },
    {
      id: 2,
      src: "/images/book-pics/book-pic (2).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
    {
      id: 23,
      src: "/images/marketing_books/book-mockup-of-a-child-on-a-sofa-23723.png",
      caption: "Skye with her Paperback- Portrait Book",
    },
    {
      id: 24,
      src: "/images/marketing_books/mockup-of-a-horizontal-book-placed-next-to-a-stuffed-animal-30943.png",
      caption: "Paperback- Landscape",
    },
    {
      id: 3,
      src: "/images/book-pics/book-pic (3).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
    {
      id: 4,
      src: "/images/book-pics/book-pic (4).jpg",
      caption:
        "Hardcover Sleeve- Portrait (Blue) & Hardcover- Landscape (Old Leather)",
    },
    {
      id: 21,
      src: "/images/marketing_books/book-mockup-of-a-girl-playing-with-a-magnifying-glass-in-her-living-room-m26924.png",
      caption: "Lily with Her Paperback- Portrait Book",
    },
    {
      id: 5,
      src: "/images/book-pics/book-pic (5).jpg",
      caption: "Hardcover- Landscape",
    },
    {
      id: 20,
      src: "/images/marketing_books/black-iphone-4-vertical-park.png",
      caption: "Lily's PDF Book on an iPhone",
    },
    {
      id: 6,
      src: "/images/book-pics/book-pic (6).jpg",
      caption: "Hardcover- Landscape",
    },
    {
      id: 7,
      src: "/images/book-pics/book-pic (7).jpg",
      caption: "Hardcover- Portrait",
    },
    {
      id: 22,
      src: "/images/marketing_books/boy-reading-a-book-mockup-at-the-studio-a19205.png",
      caption: "Dylan Reading his Portrait Paperback Book",
    },
    {
      id: 8,
      src: "/images/book-pics/book-pic (8).jpg",
      caption: "Hardcover- Portrait",
    },
    {
      id: 9,
      src: "/images/book-pics/book-pic (9).jpg",
      caption: "Back Cover of Hardcover- Landscape",
    },
    {
      id: 10,
      src: "/images/book-pics/book-pic (10).jpg",
      caption: "Back Cover of Hardcover- Landscape",
    },
    {
      id: 11,
      src: "/images/book-pics/book-pic (11).jpg",
      caption: "Paperback- Portrait",
    },
    {
      id: 12,
      src: "/images/book-pics/book-pic (12).jpg",
      caption: "Paperback- Portrait",
    },
    {
      id: 25,
      src: "/images/marketing_books/mockup-of-an-iphone-placed-on-top-of-a-tablet-m22220-r-el2.png",
      caption: "Masha's PDF Book on a Smart Phone",
    },
    {
      id: 13,
      src: "/images/book-pics/book-pic (13).jpg",
      caption: "Paperback- Portrait",
    },
    {
      id: 14,
      src: "/images/book-pics/book-pic (14).jpg",
      caption: "Paperback- Portrait",
    },
    {
      id: 15,
      src: "/images/book-pics/book-pic (15).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
    {
      id: 16,
      src: "/images/book-pics/book-pic (16).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
    {
      id: 17,
      src: "/images/book-pics/book-pic (17).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
    {
      id: 18,
      src: "/images/book-pics/book-pic (18).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
    {
      id: 19,
      src: "/images/book-pics/book-pic (19).jpg",
      caption: "Hardcover Sleeve- Portrait",
    },
  ];

  // State to track the currently selected image.
  const [selectedImage, setSelectedImage] = useState({
    id: 6,
    src: "/images/book-pics/book-pic (6).jpg",
    caption: "Hardcover- Landscape",
  });

  // Function to handle clicking on an image to make it the selected image.
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // Function to show the next image in the gallery.
  const showNextImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === selectedImage.id
    );
    if (currentIndex < images.length - 1) {
      setSelectedImage(images[currentIndex + 1]);
    }
  };

  // Function to show the previous image in the gallery.
  const showPreviousImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === selectedImage.id
    );
    if (currentIndex > 0) {
      setSelectedImage(images[currentIndex - 1]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.gallery}>
        <h2>Gallery</h2>
        <div className={styles.displayContainer}>
          <div className={styles.imageDisplay}>
            {selectedImage && (
              <div className={styles.selectedImage}>
                <button
                  className={styles.navButton}
                  onClick={showPreviousImage}
                >
                  <img className={styles.arrow} src="/images/arrow-left.png" />
                </button>
                <div>
                  <img
                    className={styles.selcImg}
                    src={selectedImage.src}
                    alt={selectedImage.caption}
                  />
                  <p>{selectedImage.caption}</p>
                </div>
                <button className={styles.navButton} onClick={showNextImage}>
                  <img className={styles.arrow} src="/images/arrow-right.png" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.imageList}>
          <Masonry
            breakpointCols={4} // Define the number of columns at different breakpoints
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGridColumn}
          >
            {images.map((image) => (
              <div key={image.id} onClick={() => handleImageClick(image)}>
                <img
                  className={`${styles.imageItem} ${
                    selectedImage === image ? styles.selected : ""
                  }`}
                  src={image.src}
                  alt={image.caption}
                />
              </div>
            ))}
          </Masonry>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
