import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSingleUser,
  selectSingleUser,
} from "./userSlice";
import UserInfo from "./UserInfo";
import UserChildren from "./UserChildren";
import UserPassword from "./UserPassword";
import styles from "../styles/UserAccount.module.css"

// UserAccount component for managing user's account information
// This is the main page that renders the UserInfo, UserChildren, and UserPassword components

const UserAccount = () => {
    const userId = useSelector((state) => state.auth.me?.id);
    const user = useSelector(selectSingleUser);
  
    const dispatch = useDispatch();

    // UseEffect hook to fetch user data when the component mounts
    useEffect(() => {
        dispatch(fetchSingleUser(userId));
      }, [dispatch, userId]);


      return (
        <section className={styles.container}>
            <h1>My Account</h1>
            <h2>Hello, {user.firstName}!</h2>
            <div className={styles.sections}>
            <div>
              <h2>My Info</h2>
              <UserInfo user={user}/>
            </div>
            <div>
              <h2>My Child Profiles</h2>
              <UserChildren user={user}/>
            </div>
            <div>
            </div>
            <div>
              <h2>Account Security</h2>
              <UserPassword />
            </div>
            </div>
        </section>
      )
};

export default UserAccount;

