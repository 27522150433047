import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BookType from "./components/BookType";
import CoverColorFont from "./components/CoverColorFont";
import Dedication from "./components/Dedication";
import { updateStoryAsync } from "../create/storySlice";
import { createBook } from "../book/bookSlice";
import { addItems, addOrderItems } from "../order/orderItemSlice";
import styles from "../styles/BookSelection.module.css";

/** 
 The BookSelection page acts as a central hub for coordinating the state and rendering of its child components: 
 BookType, CoverColorFont, and Dedication. Each of these components is conditionally rendered based on specific 
 state variables like showRenderColorChoice, showRenderFontChoice, and showRenderDedication. 
 The visibility of these components is dynamically controlled: as the user progresses through the book customization process, 
 making selections in each component, the corresponding state variables are updated to true, triggering the rendering 
 of the next component in the sequence. This design ensures a smooth and logical flow of the book customization process.
*/

const BookSelection = () => {
  const userId = useSelector((state) => state.auth.me?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const name = JSON.parse(localStorage.getItem("name")) || "";
  const childName = JSON.parse(localStorage.getItem("childName")) || null;
  const storyId = JSON.parse(localStorage.getItem("storyId")) || "";
  const uploadedImageUrls =
    JSON.parse(localStorage.getItem("uploadedImgUrls")) || [];
  const [dedication, setDedication] = useState("");

  const [presignedUrls, setPresignedUrls] = useState([]);
  const [choiceDisabled, setChoiceDisabled] = useState(true);
  const [showRenderColorChoice, setShowRenderColorChoice] = useState(false);
  const [showRenderFontChoice, setShowRenderFontChoice] = useState(false);
  const [showRenderDedication, setShowRenderDedication] = useState(false);
  const [showRenderSleeveOptions, setShowRenderSleeveOptions] = useState(false);
  const [bookTypeChoice, setBookTypeChoice] = useState("");
  const [coverColorChoice, setCoverColorChoice] = useState("#FFFFFF");
  const [coverFontChoice, setCoverFontChoice] = useState("");
  const [price, setPrice] = useState(0);
  const [stripeId, setStripeId] = useState("");
  const [sleeveBio, setSleeveBio] = useState("");
  const storyName = JSON.parse(localStorage.getItem("storyName")) || "";
  const numPages = JSON.parse(localStorage.getItem("numPages")) || "";

  //Handles the name that the Dedication component uses based on whether or not the user is logged in
  //with a default generic name of "Little One"
  let dedicationName;
  if (childName) {
    dedicationName = childName;
  } else if (name) {
    dedicationName = name;
  } else {
    dedicationName = "Little One";
  }

  const [sleeveImgIndex, setSleeveImgIndex] = useState(null);
  const [customBio, setCustomBio] = useState(false);
  const [generatedBio, setGeneratedBio] = useState("");

  //Hook that utomatically scrolls to the bottom of the page when a new component is rendered
  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [
    bookTypeChoice,
    showRenderColorChoice,
    showRenderFontChoice,
    showRenderDedication,
    showRenderSleeveOptions,
  ]);

  //Hook that creates temporary access urls to display the user's uploaded images from our AWS SW Bucket
  //these are displayed when the user selects the Hardcover Sleeve book option and they are selecting the child's bio image
  useEffect(() => {
    // Creates an array to store the promises from the axios requests
    const fetchPromises = uploadedImageUrls.map((imageUrl) => {
      return axios
        .get("/api/awsS3/images", { params: { s3Url: imageUrl } })
        .then((response) => {
          // Handle the response here, e.g., push the fetched URLs to a state or use them as needed
          const fetchedS3Url = response.data;
          return fetchedS3Url;
          // Return the URL to be collected in the Promise.all
        })
        .catch((error) => {
          console.error("Error fetching S3 URL:", error);
          return null; // Return null in case of an error
        });
    });

    // Use Promise.all to wait for all requests to complete
    Promise.all(fetchPromises).then((fetchedUrls) => {
      // Filter out any null values (in case of errors)
      const filteredUrls = fetchedUrls.filter((url) => url !== null);
      // Update your state with the fetched URLs
      setPresignedUrls(filteredUrls);
    });

    //enables and disables book option choices based on what customization Lulu Printing allows
    //ex: hardcover and sleeve must be 24 pages or more
    if (Number(numPages) < 24) {
      setChoiceDisabled(true);
    } else {
      setChoiceDisabled(false);
    }
  }, [userId]);

  //Function that resets the book information that the user was working on from local storage so they can start from scratch
  function clearBookCreationLocalStorage() {
    const keysToClear = [
      "numPages",
      "childName",
      "name",
      "childAge",
      "age",
      "childGender",
      "childId",
      "gender",
      "interest",
      "childInterest",
      "extras",
      "addExtras",
      "useCustomPrompt",
      "customPrompt",
      "response",
      "pageContents",
      "storyName",
      "storyId",
      "LOADING",
      "SHOW_STORY_PROMPT",
      "SHOW_CHILD_PROFILE_PROMPT",
      "SHOW_CREATE_ACCOUNT_PROMPT",
      "SHOW_RENDER_PAGES",
      "SHOW_UPLOAD_IMAGES",
      "SHOW_NEW_ACCOUNT",
      "SHOW_CHILD_SELECTION",
      "CONFIRMED_STORY",
      "USE_PROFILE_PARAMS",
    ];

    keysToClear.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  //Function to handle adding book to cart
  //The bio and the dedication get uploaded to the AWS S3 bucket for storage.
  //The Book object gets created and stored in our Heroku Postgres db.
  //Lastly, the local storage gets cleared so users can start fresh with a new book.
  const handleAddToCart = async (e) => {
    e.preventDefault();

    const uploadBio = async () => {
      let formData;

      if (customBio) {
        const textBlob = new Blob([sleeveBio], { type: "text/plain" });
        formData = new FormData();
        formData.append(
          "textFile",
          textBlob,
          `dedication-from-user${userId}-to${dedicationName}.txt`
        );
      } else {
        const textBlob = new Blob([generatedBio], { type: "text/plain" });
        formData = new FormData();
        formData.append(
          "textFile",
          textBlob,
          `dedication-from-user${userId}-to${dedicationName}.txt`
        );
      }

      const response = await axios.post("/api/awsS3/upload/text", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return response.data;
    };

    const uploadDedication = async () => {
      const textBlob = new Blob([dedication], { type: "text/plain" });
      const formData = new FormData();
      formData.append(
        "textFile",
        textBlob,
        `dedication-from-user${userId}-to${dedicationName}.txt`
      );

      const response = await axios.post("/api/awsS3/upload/text", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return response.data;
    };

    try {
      const dedicationResponse = await uploadDedication();
      const bioResponse = await uploadBio();

      const newBook = {
        storyName: storyName,
        bookType: bookTypeChoice,
        price: price,
        dedicationUrl: dedicationResponse,
        coverColor: coverColorChoice,
        coverFont: coverFontChoice,
        sideFlapBio: bioResponse,
        sideFlapImage: sleeveImgIndex,
        uploadedImageUrls: uploadedImageUrls,
        stripeId: stripeId,
        userId: userId ? `${userId}` : null,
      };

      const book = await dispatch(createBook(newBook));

      const bookInfo = book.payload;
      console.log(bookInfo);
      console.log(bookInfo.id);
      console.log(storyId);

      await dispatch(
        updateStoryAsync({
          id: storyId,
          bookId: bookInfo.id,
        })
      );

      if (!userId) {
        dispatch(
          addItems({ orderQTY: 1, bookId: bookInfo.id, book: bookInfo })
        );
      } else {
        dispatch(
          addOrderItems({ id: userId, bookId: bookInfo.id, orderQTY: 1 })
        );
      }

      clearBookCreationLocalStorage();
      navigate("/cart");
    } catch (error) {
      // Handle any errors that occurred during the POST request
      console.error("Error:", error);
    }
  };

  return (
    <section className={styles.container}>
      <BookType
        choiceDisabled={choiceDisabled}
        showRenderSleeveOptions={showRenderSleeveOptions}
        setShowRenderSleeveOptions={setShowRenderSleeveOptions}
        setShowRenderColorChoice={setShowRenderColorChoice}
        bookTypeChoice={bookTypeChoice}
        setBookTypeChoice={setBookTypeChoice}
        presignedUrls={presignedUrls}
        setPrice={setPrice}
        setStripeId={setStripeId}
        sleeveBio={sleeveBio}
        setSleeveBio={setSleeveBio}
        sleeveImgIndex={sleeveImgIndex}
        setSleeveImgIndex={setSleeveImgIndex}
        customBio={customBio}
        setCustomBio={setCustomBio}
        generatedBio={generatedBio}
        setGeneratedBio={setGeneratedBio}
        dedicationName={dedicationName}
      />
      <CoverColorFont
        showRenderColorChoice={showRenderColorChoice}
        showRenderFontChoice={showRenderFontChoice}
        setShowRenderFontChoice={setShowRenderFontChoice}
        setShowRenderDedication={setShowRenderDedication}
        coverColorChoice={coverColorChoice}
        setCoverColorChoice={setCoverColorChoice}
        coverFontChoice={coverFontChoice}
        setCoverFontChoice={setCoverFontChoice}
      />
      <Dedication
        showRenderDedication={showRenderDedication}
        dedication={dedication}
        setDedication={setDedication}
        dedicationName={dedicationName}
      />
      {showRenderDedication && (
        <section className={styles.addToCart}>
          <button className={styles.addButton} onClick={handleAddToCart}>
            Add to Cart
          </button>
        </section>
      )}
      <div ref={ref}></div>
    </section>
  );
};

export default BookSelection;
