import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching order items for a logged-in user
export const fetchOrderItems = createAsyncThunk(
  "fetchOrderItems",
  async (id) => {
    try {
      const { data } = await axios.get(`/api/cart/${id}`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for adding order items
export const addOrderItems = createAsyncThunk(
  "addOrderItems",
  async (orderInfo) => {
    try {
      const { data } = await axios.post(`/api/cart/${orderInfo.id}`, orderInfo);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for editing order items
export const editOrderItems = createAsyncThunk(
  "editOrderItems",
  async (orderInfo) => {
    try {
      const { data } = await axios.put(`/api/cart/${orderInfo.id}`, orderInfo);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for deleting order items
export const deleteOrderItems = createAsyncThunk(
  "deleteOrderItems",
  async (id) => {
    try {
      const { data } = await axios.delete(`/api/cart/${id}`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for creating an order from the order items
export const createOrderFromOrderItems = createAsyncThunk(
  "createOrder",
  async (orderInfo) => {
    try {
      const { data } = await axios.put(
        `/api/cart/checkout/${orderInfo.id}`,
        orderInfo
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for creating a guest order
export const createGuestOrder = createAsyncThunk(
  "createGuestOrder",
  async (orderInfo) => {
    try {
      const { data } = await axios.post(
        `/api/cart/checkout/guestcheckout`,
        orderInfo
      );
      localStorage.clear();
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Slice for order item management with an initial empty array state
export const orderItemSlice = createSlice({
  name: "orderItem",
  initialState: [],
  reducers: {
    // Reducer for fetching items for not logged-in users
    fetchItems(state, { payload }) {
      // Fetch items from local storage
      const localCart = JSON.parse(localStorage.getItem("order"));
      if (!localCart) {
        return [];
      } else {
        return localCart;
      }
    },
    // Reducer for adding items for not logged-in users
    addItems(state, { payload }) {
      fetchItems();
      //we use findIndex to determine if we already have that item in the cart
      if (state && state.length) {
        const idx = state.findIndex(
          (orderItem) => orderItem.bookId === payload.bookId
        );
        //if it exists, we increment the cart quantity
        if (idx !== -1) {
          const currentOrderQTY = state[idx].orderQTY;
          state[idx].orderQTY =
            Number(currentOrderQTY) + Number(payload.orderQTY);
          localStorage.setItem("order", JSON.stringify(state));
        } else {
          //if it doesn't exist add it to state
          state.push(payload);
          localStorage.setItem("order", JSON.stringify(state));
        }
      } else {
        state = [];
        state.push(payload);
        localStorage.setItem("order", JSON.stringify(state));
      }
    },
    // edits items for not logged in users
    editItems(state, { payload }) {
      const idx = state.findIndex(
        (orderItem) => Number(orderItem.bookId) === payload.bookId
      );
      const newQty = payload.orderQTY;
      if (newQty === 0) {
        state.splice(idx, 1);
        localStorage.setItem("order", JSON.stringify(state));
      } else {
        state[idx] = payload;
        localStorage.setItem("order", JSON.stringify(state));
      }
    },
    // clears cart for not logged in users
    clearItems(state, { payload }) {
      localStorage.clear();
      return [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderItems.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(addOrderItems.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(editOrderItems.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(deleteOrderItems.fulfilled, (state, { payload }) => {
      return [];
    });
    builder.addCase(
      createOrderFromOrderItems.fulfilled,
      (state, { payload }) => {
        return [];
      }
    );
    builder.addCase(createGuestOrder.fulfilled, (state, { payload }) => {
      return [];
    });
  },
});

export const selectOrderItem = (state) => state.orderItem;

// exporting all regular reducers to be useable in React components
export const { fetchItems, addItems, editItems, clearItems } =
  orderItemSlice.actions;

export default orderItemSlice.reducer;
