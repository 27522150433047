// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SB9JJWvkt9pK2dLjQtY2 {
  height: 5rem;
  width: 100%;
  background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(248,225,147,1) 50%, rgba(242,191,181,1) 100%);
  z-index: 80;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  border-top: white 1px solid;
  text-align: center;
}

@media only screen and (max-width: 779px) {
  .SB9JJWvkt9pK2dLjQtY2 p {
  font-size: .75rem;
  }
 }`, "",{"version":3,"sources":["webpack://./client/components/styles/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,4GAA4G;EAC5G,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE;EACA,iBAAiB;EACjB;CACD","sourcesContent":[".container {\n  height: 5rem;\n  width: 100%;\n  background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(248,225,147,1) 50%, rgba(242,191,181,1) 100%);\n  z-index: 80;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: .4rem;\n  border-top: white 1px solid;\n  text-align: center;\n}\n\n@media only screen and (max-width: 779px) {\n  .container p {\n  font-size: .75rem;\n  }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SB9JJWvkt9pK2dLjQtY2`
};
export default ___CSS_LOADER_EXPORT___;
