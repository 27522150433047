import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, signup } from "./authSlice";
import axios from "axios";
import styles from "../styles/AuthForm.module.css";
import PasswordChecklist from "react-password-checklist";

// AuthForm: A React component for user authentication. It includes forms for both login and sign-up.

const AuthForm = () => {
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // State hooks for form management and user input fields.
  const [signupForm, setSignupForm] = useState(false);
  const [loginForm, setLoginForm] = useState(true);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAgeValid, setIsAgeValid] = useState(true);
  const [validPassword, setValidPassword] = useState(false);

  // handleLogin: Dispatches login action with email and password.
  const handleLogin = (evt) => {
    evt.preventDefault();
    const email = evt.target.email.value;
    const password = evt.target.password.value;
    dispatch(login({ email, password }));
  };

  // checkEmailAvailability: Checks if the email entered by the user is available.
  const checkEmailAvailability = async () => {
    const encodedEmail = encodeURIComponent(email);
    try {
      const response = await axios.get(
        `/api/users/check-email/${encodedEmail}`
      );
      return response.data.available;
    } catch (error) {
      console.error("Error checking email availability:", error);
    }
  };

  // calculateAge: Calculates the user's age based on birthdate.
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    const age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthdate has occurred this year
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  };

  // handleBirthdateChange: Updates birthdate state and validates user's age.
  const handleBirthdateChange = (e) => {
    const newBirthdate = e.target.value;
    setBirthdate(newBirthdate);

    // Calculate age and validate
    const age = calculateAge(newBirthdate);
    setIsAgeValid(age >= 13);
  };

  // submitForm: Validates password and dispatches signup action.
  const submitForm = async () => {
    if (!validPassword) {
      alert(
        "Passwords must match and contain at least one number and one uppercase and lowercase letter, at least one special character, and at least 8 or more characters."
      );
    } else {
      // Dispatch the addUser action with the newUser data
      const newUserParams = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthdate: birthdate,
        password: password,
      };

      try {
        await dispatch(signup(newUserParams));
      } catch (err) {
        console.log("Error signing up");
      }
    }
  };

  // handleSubmitForm: Handles form submission with email availability check.
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    try {
      const response = await checkEmailAvailability();

      console.log(response);

      if (!response) {
        console.log("not available");
        alert("This email is already in use. Please use a different email.");
      } else {
        submitForm();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // JSX for rendering login and sign-up forms.
  return (
    <div>
      {loginForm && (
        <section className={styles.container}>
          <h2 className={styles.header}>Sign In</h2>
          <p className={styles.subHeader}>
            Sign in to make the book creation process a breeze, access your
            child profiles, and more!
          </p>
          <div className={styles.authFormContainer}>
            <form onSubmit={handleLogin} className={styles.form}>
              <label htmlFor="email">Email</label>
              <input name="email" type="text" />
              <label htmlFor="password">Password</label>
              <input name="password" type="password" />
              <button type="submit" className={styles.submitBtn}>
                Sign In
              </button>
              {error && <div> {error} </div>}
            </form>
          </div>
          <hr className={styles.line}></hr>
          <div className={styles.switch}>
            <h2 className={styles.header2}>Sign Up</h2>

            <button
              className={styles.button}
              onClick={() => {
                setLoginForm(false);
                setSignupForm(true);
              }}
            >
              Create An Account
            </button>
          </div>
        </section>
      )}
      {signupForm && (
        <section className={styles.container}>
          <h2 className={styles.header}>Create An Account</h2>
          <p className={styles.subHeader}>
            Welcome to MyCustomStories! We're happy to have you. We make it
            quick and easy to set up an account and NEVER share your information
            with any third parties.
          </p>
          <div className={styles.authFormContainer}>
            <hr className={styles.authFormLine}></hr>
            <form onSubmit={handleSubmitForm} className={styles.form}>
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="firstName">First Name</label>
              <input
                name="firstName"
                type="text"
                value={firstName}
                pattern="[A-Za-z]{1,32}"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <label htmlFor="lastName">Last Name</label>
              <input
                name="lastName"
                value={lastName}
                type="text"
                pattern="[A-Za-z]{1,32}"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <label htmlFor="birthDate">Birth Date</label>
              <input
                name="birthDate"
                type="date"
                value={birthdate}
                onChange={handleBirthdateChange}
                required
              />
              {isAgeValid ? null : (
                <p className="invalid">
                  You must be at least 13 years old to register.
                </p>
              )}
              <label htmlFor="password">Password*</label>
              <input
                name="password"
                type="password"
                value={password}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+\-]).{8,16}$"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+\-]).{8,16}$"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <PasswordChecklist
                className={styles.passCheck}
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                value={password}
                valueAgain={confirmPassword}
                onChange={(isValid) => setValidPassword(isValid)}
              />
              <button type="submit" className={styles.submitBtn}>
                Create Account
              </button>
              {error && <div> {error} </div>}
            </form>
          </div>
          <hr className={styles.line}></hr>
          <div className={styles.switch}>
            <h2 className={styles.header2}>Already Have an Account?</h2>
            <button
              className={styles.button}
              onClick={() => {
                setLoginForm(true);
                setSignupForm(false);
              }}
            >
              Sign In
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default AuthForm;
