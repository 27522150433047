import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteOrderItems,
  clearItems,
} from "../order/orderItemSlice";
import styles from "../styles/Confirmation.module.css";

const Confirmation = () => {
  const userId = useSelector((state) => state.auth.me?.id);
  const dispatch = useDispatch();

    /**
   * Click event handler
   * @param {onClick} event
   * @fires when clear cart is clicked
   * @dispatches an action to the Redux store to clear the entire cart
   */
    const handleClear = async () => {
      if (!userId) {
        dispatch(clearItems());
      } else {
        await dispatch(deleteOrderItems(userId));
      }
    };

    //Clears the user's cart when the component mounts or the userId changes
    useEffect(() => {
      handleClear()
    }, [userId])    

  //JSX for Confirmation page
  return (
    <div className={styles.container}>
        <img src="/images/user-owl-icons/owl-logo.png" className={styles.owl} alt="owl-logo"/>
      <h1>Your order is confirmed.</h1>
      <p><b>Thank you for shopping with us!</b></p>
    </div>
  );
};

export default Confirmation;
