import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../auth/authSlice";
import Modal from "react-modal";
import CartModal from "../cart/CartModal";
import styles from "../styles/Navbar.module.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // States for managing the visibility of different modals
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [isGuestMenuModalOpen, setIsGuestMenuModalOpen] = useState(false);

  // Selectors for user authentication and admin status
  const isLoggedIn = useSelector((state) => !!state.auth.me);
  const isAdmin = useSelector((state) => state.auth.me && state.auth.me.isAdmin);
  const userId = useSelector((state) => state.auth.me?.id);

  // Function to handle logout and redirect to the home page
  const logoutAndRedirectHome = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/home");
  };

  // Effects to handle closing modals when the page refreshes or the user clicks onto a new page
  useEffect(() => {
    const closeModalsOnPageRefresh = (e) => {
      if (isCartModalOpen || isMenuModalOpen || isGuestMenuModalOpen) {
        // Close the modals
        setIsCartModalOpen(false);
        setIsMenuModalOpen(false);
        setIsGuestMenuModalOpen(false);
      }
    };

    window.addEventListener("beforeunload", closeModalsOnPageRefresh);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", closeModalsOnPageRefresh);
    };
  }, [isCartModalOpen, isMenuModalOpen, isGuestMenuModalOpen]);

  useEffect(() => {
    const closeModalsOnClickOutside = (e) => {
      if (
        (isCartModalOpen && e.target.id === "cart-modal") ||
        (isMenuModalOpen && e.target.id === "menu-modal") ||
        (isGuestMenuModalOpen && e.target.id === "guest-menu-modal")
      ) {
        // Modals are open and the click is inside the modal, do nothing
        return;
      }
  
      // Check if the click is inside the modal content
      if (
        e.target.closest("#cart-modal") ||
        e.target.closest("#menu-modal") ||
        e.target.closest("#guest-menu-modal")
      ) {
        // Click is inside the modal content, do nothing
        return;
      }
  
      // Modals are open, but the click is outside, close them
      setIsCartModalOpen(false);
      setIsMenuModalOpen(false);
      setIsGuestMenuModalOpen(false);
    };
  
    document.addEventListener("mousedown", closeModalsOnClickOutside);
  
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", closeModalsOnClickOutside);
    };
  }, [isCartModalOpen, isMenuModalOpen, isGuestMenuModalOpen]);
  const openCartModal = () => {
    setIsCartModalOpen(true);
  };

  const closeCartModal = () => {
    setIsCartModalOpen(false);
  };

  const openMenuModal = () => {
    setIsMenuModalOpen(true);
  };

  const closeMenuModal = () => {
    setIsMenuModalOpen(false);
  };

  const openGuestMenuModal = () => {
    setIsGuestMenuModalOpen(true);
  };

  const closeGuestMenuModal = () => {
    setIsGuestMenuModalOpen(false);
  };

  return (
    <div>
      <nav className={styles.navParent}>
        {isLoggedIn ? (
          <div className={styles.container}>
            <button className={styles.modalButton} onClick={openMenuModal}>
              <span className="material-symbols-outlined">menu</span>
            </button>
            <div className={styles.linksContainer}>
              <Link to="/home" className={styles.navLink}>
                Home
              </Link>
              <Link to="/create" className={styles.navLink}>
                Create
              </Link>
              <Link to="/gallery" className={styles.navLink}>
                Gallery
              </Link>
            </div>
            <img src="/images/mcs_logo.png" className={styles.logo} />
            <img
              src="/images/user-owl-icons/owl-logo.png"
              className={styles.owlIcon}
            />
            <div className={styles.left}>
              {isAdmin && (
                <Link to={"/admin"}>Admin</Link>
              )}
              <Link to={`/users/${userId}`} className={styles.navLink}>
                <span className="material-symbols-outlined">person</span>
              </Link>
              <button className={styles.button} onClick={() => openCartModal()}>
                <span className="material-symbols-outlined">shopping_cart</span>
              </button>
              <button
                type="button"
                onClick={logoutAndRedirectHome}
                className={styles.authButton}
              >
                Logout
              </button>
              <CartModal
                id="cart-modal"
                isModalOpen={isCartModalOpen}
                closeModal={closeCartModal}
              />
              <Modal
                id="menu-modal"
                isOpen={isMenuModalOpen}
                onRequestClose={closeMenuModal}
                contentLabel="Menu Modal"
                className={styles.menuModal}
                overlayClassName={styles.overlay}
                shouldCloseOnOverlayClick={true}
              >
                <Link to="/home" className={styles.modalLink} onClick={closeMenuModal}>
                  Home
                </Link>
                <Link to="/create" className={styles.modalLink} onClick={closeMenuModal}>
                  Create
                </Link>
                <Link to="/gallery" className={styles.modalLink} onClick={closeMenuModal}>
                  Gallery
                </Link>
                <Link to={`/users/${userId}`} className={styles.modalLink} onClick={closeMenuModal}>
                  Account
                </Link>
                <button
                  type="button"
                  onClick={logoutAndRedirectHome}
                  className={styles.modalLink}
                >
                  Logout
                </button>
                <button className={styles.closeButton} onClick={closeMenuModal}>
                  Close
                </button>
              </Modal>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.linksContainer}>
              {/* The navbar will show these links before a user logs in and when a user logs out */}
              <button
                className={styles.modalButton}
                onClick={openGuestMenuModal}
              >
                <span className="material-symbols-outlined">menu</span>
              </button>
              <Link to="/home" className={styles.navLink}>
                Home
              </Link>
              <Link to="/create" className={styles.navLink}>
                Create
              </Link>
              <Link to="/gallery" className={styles.navLink}>
                Gallery
              </Link>
            </div>
            <Link to="/home">
              <img src="/images/mcs_logo.png" className={styles.logo} />
              <img
                src="/images/user-owl-icons/owl-logo.png"
                className={styles.owlIcon}
              />
            </Link>
            <div className={styles.left}>
              <button className={styles.button} onClick={() => openCartModal()}>
                <span className="material-symbols-outlined">shopping_cart</span>
              </button>
              <CartModal
                id="cart-modal"
                isModalOpen={isCartModalOpen}
                closeModal={closeCartModal}
              />
              <Link to="/signup" className={styles.authButton}>
                Sign In / Sign Up
              </Link>
              <Modal
               onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}
                id="guest-menu-modal"
                isOpen={isGuestMenuModalOpen}
                onRequestClose={closeGuestMenuModal}
                contentLabel="Menu Modal"
                className={styles.menuModal}
                overlayClassName={styles.overlay}
                shouldCloseOnOverlayClick={true}
              >
                <Link to="/home" className={styles.modalLink} onClick={closeGuestMenuModal}>
                  Home
                </Link>
                <Link to="/create" className={styles.modalLink} onClick={closeGuestMenuModal}>
                  Create
                </Link>
                <Link to="/gallery" className={styles.modalLink} onClick={closeGuestMenuModal}>
                  Gallery
                </Link>
                <Link to="/signup" className={styles.modalLink} onClick={closeGuestMenuModal}>
                  Sign In /<br />
                  Sign Up
                </Link>
                <button
                  className={styles.closeButton}
                  onClick={closeGuestMenuModal}
                >
                  Close
                </button>
              </Modal>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
