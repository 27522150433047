import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrder } from "../../order/ordersSlice";
import { editOrderFulfillment } from "../../order/orderSlice";
import { fetchBook, selectBook } from "../../book/bookSlice";
import { fetchStoryAsync } from "../../create/storySlice";
import Modal from "react-modal";
import styles from "../../styles/Admin.module.css";

// OrderCard: A React component for displaying and managing a single order in the admin interface.
// It includes functionalities like viewing order details, managing order fulfillment status, and deleting an order.

const OrderCard = ({ order }) => {
  const dispatch = useDispatch();
  const bookIds = order.bookIds; // Extract book IDs from the order object.
  const [booksWithDetails, setBooksWithDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showStoryText, setShowStoryText] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // fetchBookAndStoryDetails: Function to fetch book and story details for each book in the order.
  const fetchBookAndStoryDetails = async () => {
    const booksData = [];
    for (const bookId of bookIds) {
      const bookResult = await dispatch(fetchBook(bookId));
      const book = bookResult.payload;

      if (book) {
        const storyResult = await dispatch(fetchStoryAsync(bookId));
        const story = storyResult.payload;
        booksData.push({ book, story });
      }
    }
    setBooksWithDetails(booksData);
  };

  // useEffect to fetch book and story details when the component mounts or bookIds change.
  useEffect(() => {
    if (bookIds) {
      if (bookIds.length > 0) {
        fetchBookAndStoryDetails();
      }
    }
  }, [dispatch, bookIds]);

  // handleOrderFulfillment: Function to toggle the fulfillment status of an order.
  const handleOrderFulfillment = async (e) => {
    e.preventDefault();
    await dispatch(
      editOrderFulfillment({
        id: order.id,
        fulfilled: !order.fulfilled,
      })
    );
    window.location.reload();
  };

  // handleDeleteOrder: Function to delete an order.
  const handleDeleteOrder = async (e) => {
    e.preventDefault();
    await dispatch(deleteOrder(order.id));
  };

  // Modal control functions.
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Custom styles for the modal component.
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 120,
    },
    overlay: { zIndex: 100 },
  };

  // JSX rendering for the OrderCard component.
  // It includes order details, a list of books in the order, and controls to manage the order.
  return (
    <div className={styles.orderCard} key={order.id}>
      <div className={styles.orderInfo}>
        <h2>Order No. {order.id}</h2>
        <p>
          <b>Placed by:</b> {order.name}
        </p>
        <p>
          <b>Email:</b> {order.email}
        </p>
        <p>
          <b>Order Total:</b> ${order.orderTotal}
        </p>
        <p>
          <b>Date Placed:</b> {order.date}
        </p>
        <p>
          <b>Fulfilled:</b> {order.fulfilled ? "True" : "False"}
        </p>
      </div>
      <div className={styles.booksHeader}>
        <h2>Book(s)</h2>
        <button
          className={styles.booksButton}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? (
            <span className="material-symbols-outlined">expand_more</span>
          ) : (
            <span className="material-symbols-outlined">expand_less</span>
          )}
        </button>
      </div>
      {showDetails ? (
        <div>
          <ul>
            {booksWithDetails.map(({ book, story }) => (
              <li key={book.id} className={styles.bookDetails}>
                <div>
                  <p>
                    <b>Title:</b> {book.storyName}
                  </p>{" "}
                  <p>
                    <b>Book Type:</b> {book.bookType}
                  </p>
                  <p>
                    <b>Book Price:</b> ${book.price}
                  </p>{" "}
                  <p>
                    <b>Dedication URL:</b> {book.dedicationUrl}
                  </p>
                  <p>
                    <b>Cover Color:</b> {book.coverColor}
                  </p>{" "}
                  <p>
                    <b>Cover Font:</b> {book.coverFont}
                  </p>
                  <p>
                    <b>Bio URL:</b> {book.sideFlapBio}
                  </p>{" "}
                  <p>
                    <b>Bio Image URL:</b> {book.sideFlapImage}
                  </p>
                  <p>
                    <b>Uploaded Image URLs:</b> {book.uploadedImageUrls}
                  </p>
                </div>
                <div className={styles.storyHeader}>
                  <h2>Story Text:</h2>
                  <button
                    className={styles.booksButton}
                    onClick={() => setShowStoryText(!showStoryText)}
                  >
                    {showStoryText ? (
                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    ) : (
                      <span className="material-symbols-outlined">
                        expand_less
                      </span>
                    )}
                  </button>
                </div>
                {story && showStoryText ? (
                  <div className={styles.storyInfo}>
                    {story.pageTexts?.map((pageText) => {
                      return (
                        <div key={pageText.id} className={styles.pageTexts}>
                          <p>
                            <b>Page Number:</b> {pageText.pageNumber}
                          </p>
                          <p>
                            <b>s3URL:</b> {pageText.s3Url}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <button onClick={handleOrderFulfillment}>
        {order.fulfilled ? "Mark as Unfulfilled" : "Mark as Fulfilled"}
      </button>
      <button onClick={openModal}>
        <span class="material-symbols-outlined">delete</span>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.modal}
        style={customStyles}
      >
        <section className={styles.modal}>
          <h2>Are you sure you want to delete this order?</h2>
          <p>This order will be lost forever.</p>
          <div className={styles.buttons}>
            <button className={styles.button} onClick={handleDeleteOrder}>
              Yes, delete.
            </button>
            <button className={styles.button} onClick={closeModal}>
              Nevermind!
            </button>
          </div>
        </section>
      </Modal>
    </div>
  );
};

export default OrderCard;
