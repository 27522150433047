import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import NewAccountPrompt from "./components/NewAccountPrompt";
import ChildProfilePrompt from "./components/ChildProfilePrompt";
import CreateAccountPrompt from "./components/CreateAccountPrompt";
import ChildSelection from "./components/ChildSelection";
import RenderPages from "./components/RenderPages";
import StoryPrompt from "./components/StoryPrompt";
import UploadImages from "./components/UploadImages";
import styles from "../styles/Create.module.css";

/*
 Main component for the "Create" page, managing the story creation process.
 This components renders and passes props to ChildProfilePrompt, ChildSelection,
 CreateAccountPrompt, NewAccountPrompt, RenderPages, StoryPrompt, and UploadImages.

 The idea is that each of the aforementioned components are rendered conditionally based on
 the state of their respective components. When a user clicks a button such as "Next"
 when they are finished interacting with one component, it triggers the next component to render
 so that the story creation process feels like a text conversation with our owl mascot.

 Many of the user's selections throughout the story creation process are stored in 
 local storage so that if the page is refreshed, they won't lose their progress
 */

const Create = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.me);
  const userId = useSelector((state) => state.auth.me?.id);
    // Assigning a unique ID for the user session if they do not have a userID
  const id = isLoggedIn ? userId : uuidv4();
  const [numPages, setNumPages] = useLocalStorage("numPages", "10");
  const [name, setName] = useLocalStorage("name", "");
  const [age, setAge] = useLocalStorage("age", "");
  const [gender, setGender] = useLocalStorage("gender", "girl");
  const [interest, setInterest] = useLocalStorage("interest", "");
  const [extras, setExtras] = useLocalStorage("extras", "");
  const [addExtras, setAddExtras] = useLocalStorage("addExtras", false);
  const [useCustomPrompt, setUseCustomPrompt] = useLocalStorage(
    "useCustomPrompt",
    false
  );
  const [customPrompt, setCustomPrompt] = useLocalStorage("customPrompt", "");
  const [response, setResponse] = useLocalStorage("response", "");
  const [pageContents, setPageContents] = useLocalStorage("pageContents", []);
  const [storyName, setStoryName] = useLocalStorage("storyName", "");
  const [storyId, setStoryId] = useLocalStorage("storyId", null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useLocalStorage("LOADING", true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [useProfileParams, setUseProfileParams] = useLocalStorage(
    "USE_PROFILE_PARAMS",
    false
  );
  const [confirmedStory, setConfirmedStory] = useLocalStorage(
    "CONFIRMED_STORY",
    false
  );
  const [childAge, setChildAge] = useLocalStorage("childAge", "");
  const [childGender, setChildGender] = useLocalStorage("childGender", "");
  const [childName, setChildName] = useLocalStorage("childName", "");
  const [childInterest, setChildInterest] = useLocalStorage(
    "childInterest",
    ""
  );
  const [childId, setChildId] = useLocalStorage("childId", "");

  const [showRenderStoryPrompt, setShowRenderStoryPrompt] = useLocalStorage(
    "SHOW_STORY_PROMPT",
    false
  );
  const [showChildProfilePrompt, setShowChildProfilePrompt] = useLocalStorage(
    "SHOW_CHILD_PROFILE_PROMPT",
    false
  );
  const [showCreateAccountPrompt, setShowCreateAccountPrompt] = useLocalStorage(
    "SHOW_CREATE_ACCOUNT_PROMPT",
    false
  );
  const [showRenderPages, setShowRenderPages] = useLocalStorage(
    "SHOW_RENDER_PAGES",
    false
  );
  const [showRenderUploadImages, setShowRenderUploadImages] = useLocalStorage(
    "SHOW_UPLOAD_IMAGES",
    false
  );
  const [newAccountRender, setNewAccountRender] = useLocalStorage(
    "SHOW_NEW_ACCOUNT",
    false
  );
  const [showChildSelectionPrompt, setShowChildSelectionPrompt] =
    useLocalStorage("SHOW_CHILD_SELECTION", true);

  const [clearLocalStorage, setClearLocalStorage] = useState(false);
  const ref = useRef(null);

  
  // Custom hook to synchronize state with localStorage
  function useLocalStorage(key, initial) {
    const [value, setValue] = useState(() => {
      if (typeof window !== "undefined") {
        const saved = window.localStorage.getItem(key);
        if (saved !== null) {
          return JSON.parse(saved);
        }
      }

      return initial;
    });

    useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
  }

  // Scroll to the bottom of the page when certain states change
  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [
    showChildProfilePrompt,
    showChildSelectionPrompt,
    showCreateAccountPrompt,
    showRenderPages,
    showRenderStoryPrompt,
    showRenderUploadImages,
    loading,
  ]);

  // Modal open and close functions
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Custom styles for the modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 120,
    },
    overlay: { zIndex: 100 },
  };

  // Function to clear localStorage and reset the app state
  const clearLocalStorageData = () => {
    const keysToClear = [
      "numPages",
      "childName",
      "name",
      "childAge",
      "age",
      "childGender",
      "childId",
      "gender",
      "interest",
      "childInterest",
      "extras",
      "addExtras",
      "useCustomPrompt",
      "customPrompt",
      "response",
      "pageContents",
      "storyName",
      "storyId",
      "LOADING",
      "SHOW_STORY_PROMPT",
      "SHOW_CHILD_PROFILE_PROMPT",
      "SHOW_CREATE_ACCOUNT_PROMPT",
      "SHOW_RENDER_PAGES",
      "SHOW_UPLOAD_IMAGES",
      "SHOW_NEW_ACCOUNT",
      "SHOW_CHILD_SELECTION",
      "CONFIRMED_STORY",
      "USE_PROFILE_PARAMS",
    ];

    keysToClear.forEach((key) => {
      localStorage.removeItem(key);
    });
    window.location.reload();
  };

  // Effect to listen to changes in the clearLocalStorage flag
  useEffect(() => {
    if (clearLocalStorage) {
      clearLocalStorageData();
    }
  }, [clearLocalStorage]);

  // Function to handle the submission and processing of story data
  const handleSubmit = async () => {
    try {
      setResponse("");
      setLoading(true);

      function toTitleCase(str) {
        return str
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }

      //Generating the params to send to ChatGPT4 API for story generation
      let generateParams;
      if (useCustomPrompt) {
        if (useProfileParams) {
          generateParams = {
            numPages: numPages,
            age: childAge,
            gender: childGender,
            name: toTitleCase(childName),
            prompt: customPrompt,
          };
        } else {
          generateParams = {
            numPages: numPages,
            age: age,
            gender: gender.toLowerCase(),
            name: toTitleCase(name),
            prompt: customPrompt,
          };
        }
      } else if (!useCustomPrompt) {
        if (useProfileParams) {
          generateParams = {
            numPages: numPages,
            age: childAge,
            gender: childGender,
            name: toTitleCase(childName),
            interest: childInterest,
            extras: extras,
          };
        } else {
          generateParams = {
            numPages: numPages,
            age: age,
            gender: gender.toLowerCase(),
            name: toTitleCase(name),
            interest: interest,
            extras: extras,
          };
        }
      }

      const response = await fetch("/api/generate/text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(generateParams),
      });

      if (!response.body) {
        throw new Error("Stream not supported");
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        setResponse((prev) => prev + value);
        setShowRenderPages(true);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className={styles.bgContainer}>
      <div className={styles.container}>
        <h1 className={styles.header}>Create Your Story</h1>
        <button onClick={openModal} className={styles.restartPage}>
          <img src="/images/restart.png" className={styles.restartIcon} />
        </button>
        {isLoggedIn ? (
          <div>
            <div>
              {newAccountRender && <NewAccountPrompt id={id} />}
              {showChildSelectionPrompt && (
                <ChildSelection
                  setChildAge={setChildAge}
                  setChildGender={setChildGender}
                  setChildName={setChildName}
                  setChildInterest={setChildInterest}
                  setChildId={setChildId}
                  setShowRenderStoryPrompt={setShowRenderStoryPrompt}
                  id={id}
                  setUseProfileParams={setUseProfileParams}
                />
              )}
              {showRenderStoryPrompt && (
                <StoryPrompt
                  numPages={numPages}
                  name={name}
                  gender={gender}
                  age={age}
                  interest={interest}
                  setNumPages={setNumPages}
                  setAge={setAge}
                  setGender={setGender}
                  setName={setName}
                  setInterest={setInterest}
                  childAge={childAge}
                  childName={childName}
                  childGender={childGender}
                  childInterest={childInterest}
                  response={response}
                  setResponse={setResponse}
                  handleSubmit={handleSubmit}
                  setShowRenderPages={setShowRenderPages}
                  setNextStep={setShowChildProfilePrompt}
                  setLoading={setLoading}
                  extras={extras}
                  setExtras={setExtras}
                  addExtras={addExtras}
                  setAddExtras={setAddExtras}
                  useCustomPrompt={useCustomPrompt}
                  setUseCustomPrompt={setUseCustomPrompt}
                  customPrompt={customPrompt}
                  setCustomPrompt={setCustomPrompt}
                  showRenderPages={showRenderPages}
                  showCreateAccountPrompt={showCreateAccountPrompt}
                />
              )}
              {showChildProfilePrompt && (
                <ChildProfilePrompt
                  numPages={numPages}
                  name={name}
                  gender={gender}
                  age={age}
                  interest={interest}
                  setChildId={setChildId}
                  setResponse={setResponse}
                  handleSubmit={handleSubmit}
                  setShowRenderPages={setShowRenderPages}
                  setLoading={setLoading}
                  id={id}
                />
              )}
              {showRenderPages && (
                <RenderPages
                  response={response}
                  setShowRenderUploadImages={setShowRenderUploadImages}
                  id={id}
                  name={name}
                  childName={childName}
                  handleSubmit={handleSubmit}
                  pageContents={pageContents}
                  setPageContents={setPageContents}
                  storyName={storyName}
                  setStoryName={setStoryName}
                  loading={loading}
                  setLoading={setLoading}
                  confirmedStory={confirmedStory}
                  setConfirmedStory={setConfirmedStory}
                  setStoryId={setStoryId}
                />
              )}
              {showRenderUploadImages && (
                <UploadImages
                  childName={childName}
                  childId={childId}
                  name={name}
                  id={id}
                  setClearLocalStorage={setClearLocalStorage}
                />
              )}
            </div>
          </div>
        ) : (
          <div>
            <StoryPrompt
              numPages={numPages}
              name={name}
              gender={gender}
              age={age}
              interest={interest}
              setNumPages={setNumPages}
              setAge={setAge}
              setGender={setGender}
              setName={setName}
              setInterest={setInterest}
              childAge={childAge}
              childName={childName}
              childGender={childGender}
              childInterest={childInterest}
              response={response}
              setResponse={setResponse}
              setNextStep={setShowCreateAccountPrompt}
              setShowRenderPages={setShowRenderPages}
              setLoading={setLoading}
              extras={extras}
              setExtras={setExtras}
              addExtras={addExtras}
              setAddExtras={setAddExtras}
              useCustomPrompt={useCustomPrompt}
              setUseCustomPrompt={setUseCustomPrompt}
              customPrompt={customPrompt}
              setCustomPrompt={setCustomPrompt}
              showRenderPages={showRenderPages}
              showCreateAccountPrompt={showCreateAccountPrompt}
            />
            {showCreateAccountPrompt && (
              <CreateAccountPrompt
                name={name}
                gender={gender}
                age={age}
                interest={interest}
                setChildId={setChildId}
                firstName={firstName}
                lastName={lastName}
                email={email}
                birthdate={birthdate}
                password={password}
                confirmPassword={confirmPassword}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setBirthdate={setBirthdate}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                handleSubmit={handleSubmit}
                setNewAccountRender={setNewAccountRender}
                setShowChildSelectionPrompt={setShowChildSelectionPrompt}
                setShowRenderStoryPrompt={setShowRenderStoryPrompt}
                setShowRenderPages={setShowRenderPages}
                setUseProfileParams={setUseProfileParams}
                showRenderPages={showRenderPages}
              />
            )}
            {showRenderPages && (
              <RenderPages
                response={response}
                setShowRenderUploadImages={setShowRenderUploadImages}
                id={id}
                name={name}
                childName={childName}
                handleSubmit={handleSubmit}
                pageContents={pageContents}
                setPageContents={setPageContents}
                storyName={storyName}
                setStoryName={setStoryName}
                setLoading={setLoading}
                loading={loading}
                confirmedStory={confirmedStory}
                setConfirmedStory={setConfirmedStory}
                setStoryId={setStoryId}
              />
            )}
            {showRenderUploadImages && (
              <UploadImages
                childName={childName}
                childId={childId}
                name={name}
                id={id}
                setClearLocalStorage={setClearLocalStorage}
              />
            )}
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={styles.restartModal}
          style={customStyles}
        >
          <section className={styles.modal}>
            <h2>Are you sure you want to start over?</h2>
            <p>This will erase all of your current story progress.</p>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                onClick={() => setClearLocalStorage(true)}
              >
                Yes, restart.
              </button>
              <button className={styles.button} onClick={closeModal}>
                Nevermind!
              </button>
            </div>
          </section>
        </Modal>
        <div ref={ref}></div>
      </div>
    </section>
  );
};

export default Create;
