import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../../auth/authSlice";
import { addChild } from "../../child/childrenSlice";
import { signup } from "../../auth/authSlice";
import styles from "../../styles/Create.module.css";
import PasswordChecklist from "react-password-checklist";

/*
  CreateAccountPrompt component handles user account creation and child profile linking.
  It manages the creation of new user accounts and associated child profiles.
 */

const CreateAccountPrompt = ({
  name,
  age,
  gender,
  interest,
  setChildId,
  firstName,
  lastName,
  email,
  birthdate,
  password,
  confirmPassword,
  setFirstName,
  setLastName,
  setEmail,
  setBirthdate,
  setPassword,
  setConfirmPassword,
  handleSubmit,
  setShowRenderPages,
  showRenderPages,
  setNewAccountRender,
  setShowChildSelectionPrompt,
  setShowRenderStoryPrompt,
  setUseProfileParams,
}) => {
  const [showCreateAccountQuestion, setShowCreateAccountQuestion] =
    useLocalStorage("SHOW_CREATE_QUESTION", true);
  const [showCreateAccountForm, setShowCreateAccountForm] = useLocalStorage(
    "SHOW_CREATE_FORM",
    false
  );

  const [isAgeValid, setIsAgeValid] = useState(true);
  const [validPassword, setValidPassword] = useState(false);

  const dispatch = useDispatch();

  // Custom hook for synchronizing state with local storage
  function useLocalStorage(key, initial) {
    const [value, setValue] = useState(() => {
      if (typeof window !== "undefined") {
        const saved = window.localStorage.getItem(key);
        if (saved !== null) {
          return JSON.parse(saved);
        }
      }

      return initial;
    });

    useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
  }

  // Function to check email availability
  const checkEmailAvailability = async () => {
    const encodedEmail = encodeURIComponent(email);
    try {
      const response = await axios.get(
        `/api/users/check-email/${encodedEmail}`
      );
      return response.data.available;
    } catch (error) {
      console.error("Error checking email availability:", error);
    }
  };

  // Function to calculate age from birthdate
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    const age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthdate has occurred this year
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  };

  // Handler for birthdate change event
  const handleBirthdateChange = (e) => {
    const newBirthdate = e.target.value;
    setBirthdate(newBirthdate);

    // Calculate age and validate
    const age = calculateAge(newBirthdate);
    setIsAgeValid(age >= 13);
  };

  // Function to submit the account creation form
  const submitForm = async () => {
    // // Check if passwords validations are met
    if (!validPassword) {
      alert(
        "Passwords must match and contain at least one number and one uppercase and lowercase letter, at least one special character, and at least 8 or more characters."
      );
    } else {
      // Dispatch the addUser action with the newUser data
      const newUserParams = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthdate: birthdate,
        password: password,
      };

      dispatch(signup(newUserParams))
        .then((response) => {
          const userId = response.payload.me.id;

          let childParams;

          if (interest) {
            childParams = {
              name: name,
              gender: gender,
              age: age,
              interests: [interest],
              userId: userId,
            }
          } else {
            childParams = {
              name: name,
              gender: gender,
              age: age,
              userId: userId,
            }
          }

          return dispatch(
            addChild(childParams)
          );
        })
        .then((childResponse) => {
          const childId = childResponse.payload.id;
          setChildId(childId);
        })
        .catch((error) => {
          console.error(error);
        });

      handleSubmit();

      setShowChildSelectionPrompt(false);
      setNewAccountRender(true);
      setUseProfileParams(true);
      setShowRenderStoryPrompt(true);
      setShowRenderPages(true);
      //manually set all of the local states to true to keep prompts rendered?
    }
  };

  // Handler for form submission event
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    
    try {
      const response = await checkEmailAvailability();
      
      console.log(response);
  
      if (!response) {
        console.log("not available");
        alert("This email is already in use. Please use a different email.");
      } else {
        submitForm();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Component to render the account creation question
  const CreateAccountQuestion = () => {
    return (
      <div className={styles.subContainerOwl}>
        <section className={styles.chatContainer}>
          <img
            src="/images/user-owl-icons/owl-logo.png"
            className={styles.chatIcon}
          />
          <div className={styles.formContainer1}>
            <div className={styles.createQuestion}>
              <span>
                Would you like to create an account and save this child profile
                to make customizing their next book a breeze?
              </span>
              <div className={styles.buttons}>
                <button
                  onClick={() => {
                    setShowCreateAccountForm(true);
                  }}
                  className={showRenderPages ? `${styles.button} ${styles.disabled} ` : `${styles.button}`}
                >
                  Sounds great!
                </button>
                <button
                  onClick={() => {
                    handleSubmit();
                    setShowChildSelectionPrompt(false);
                    setNewAccountRender(true);
                    setShowRenderStoryPrompt(true);
                    setShowRenderPages(true);
                  }}
                  className={showRenderPages ? `${styles.button} ${styles.disabled} ` : `${styles.button}`}
                >
                  No thanks.
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <div>
       {/* Conditionally render the account creation question or form */}
      {showCreateAccountQuestion && <CreateAccountQuestion />}
      {showCreateAccountForm && (
        <div className={styles.subContainerUser}>
          <div className={styles.chatContainer}>
            <section className={styles.formContainer2}>
              <form onSubmit={handleSubmitForm}>
                <h2 className={styles.newAccountHeader}>User Information</h2>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    name="firstName"
                    type="text"
                    value={firstName}
                    pattern="[A-Za-z]{1,32}"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    name="lastName"
                    value={lastName}
                    type="text"
                    pattern="[A-Za-z]{1,32}"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="birthDate">Birth Date</label>
                  <input
                    name="birthDate"
                    type="date"
                    value={birthdate}
                    onChange={handleBirthdateChange}
                    required
                  />
                  {isAgeValid ? null : (
                    <p className="invalid">
                      You must be at least 13 years old to register.
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="password">Password*</label>
                  <input
                    name="password"
                    type="password"
                    value={password}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Password must contain at least one number and one uppercase and lowercase letter, at least one special character, and at least 8 or more characters."
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <div className={styles.passCheckContainer}>
                  <PasswordChecklist
                  className={styles.passCheck}
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={8}
                    value={password}
                    valueAgain={confirmPassword}
                    onChange={(isValid) => setValidPassword(isValid)}
                  />
                  </div>
                </div>
                <button className={styles.accountFormButton} type="submit">
                  Submit
                </button>
              </form>
            </section>
          </div>
          <img
            src="/images/user-owl-icons/user-icon.png"
            className={styles.chatIcon}
          />
        </div>
      )}
    </div>
  );
};

export default CreateAccountPrompt;
