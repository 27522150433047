import React, { useState } from "react";
import styles from "../../styles/BookSelection.module.css";

//Dedication: a component that allows users to either generate a dedition via ChatGPT 4's API or
//write their own custom dedication.

const Dedication = ({ dedication, setDedication, dedicationName, showRenderDedication }) => {
const childName = JSON.parse(localStorage.getItem("childName")) || null;
  const childAge = JSON.parse(localStorage.getItem("childAge")) || null;
  const childGender = JSON.parse(localStorage.getItem("childGender")) || null;
  const gender = JSON.parse(localStorage.getItem("gender")) || "";
  const [author, setAuthor] = useState("");
  const pageContents = JSON.parse(localStorage.getItem("pageContents")) || [];

  // Function to handle the submission of dedication
    const handleSubmitDedication = async (e) => {
        e.preventDefault();
        try {
            // Function to convert input text to title case
          function toTitleCase(str) {
            return str
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          }
    
          let generateParams;
          if (childName && childAge) {
            generateParams = {
              gender: childGender,
              name: toTitleCase(childName),
              author: author,
              pageContents: pageContents,
            };
          } else {
            generateParams = {
              gender: gender.toLowerCase(),
              name: toTitleCase(name),
              author: author,
              pageContents: pageContents,
            };
          }
    
          const response = await fetch("/api/generate/text/dedication", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(generateParams),
          });
    
          if (!response.body) {
            throw new Error("Stream not supported");
          }
    
          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
    
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            setDedication((prev) => prev + value);
          }
        } catch (err) {
          console.error(err);
        }
      };

  return (
    <>
      {showRenderDedication && (
        <div>
          <section className={styles.dedication}>
            <h2>Would you like to add a complementary dedication?</h2>
            <div className={styles.dedicationInput}>
              <label htmlFor="author">Who is this dedication by?</label>
              <input
                placeholder="Your name, Mom, Dad, Grandma, Grandpa, Auntie, Uncle, etc."
                name="author"
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </div>

            <div className={styles.dedicationField}>
              <textarea
                className={styles.dedicationTA}
                value={dedication}
                placeholder={`Dear ${dedicationName}...`}
                onChange={(e) => setDedication(e.target.value)}
              />
              <button
                onClick={() => setDedication("")}
                className={styles.clearButton}
              >
                <span className="material-symbols-outlined">delete</span>
              </button>
              <div className={styles.dedicationSubHeader}>
                <span>
                  <b>Write your own or</b>
                </span>
                <span>
                  <button onClick={handleSubmitDedication}>Generate</button>
                </span>
              </div>
            </div>
          </section>
          
        </div>
      )}
    </>
  );
};

export default Dedication;