import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { addChild } from "../child/childrenSlice";
import ChildCard from "../child/ChildCard";
import styles from "../styles/UserAccount.module.css"

//Component for managing and displaying user's child profiles.
// Allows adding a new child profile via a modal form.

const UserChildren = ({ user }) => {
  const dispatch = useDispatch();
  const [newChildModalIsOpen, setNewChildModalIsOpen] = useState(false);
  const [newChildData, setNewChildData] = useState({
    name: "",
    age: "",
    birthday: "",
    gender: "",
    userId: null,
  });
  const [newChildFulfilled, setNewChildFulfilled] = useState(false);

  // Reloads the page to update the list of children once a new child is successfully added
  useEffect(() => {
    if (newChildFulfilled) {
      window.location.reload();
    }
  }, [newChildFulfilled])

  // Custom styles for the modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // Functions to open and close the modal for adding a new child
  const openNewChildModal = () => {
    setNewChildModalIsOpen(true);
  };

  const closeNewChildModal = () => {
    setNewChildModalIsOpen(false);
  };


  //Handles the submission of the new child form.
  //Dispatches the addChild action and updates the state accordingly.
  const handleNewChildDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "gender") {
      setNewChildData({
        ...newChildData,
        gender: value,
        userId: user.id,
      });
    } else {
      setNewChildData({
        ...newChildData,
        [name]: value,
        userId: user.id,
      });
    }
  };

  const handleAddNewChild = async (e) => {
    e.preventDefault();
    // Dispatch an action to add the new child

    await dispatch(addChild(newChildData));
    // Close the modal and clear the form data
    setNewChildModalIsOpen(false);
    setNewChildData({
      name: "",
      age: "",
      birthday: "",
      gender: "",
      userId: user.id,
    });
    setNewChildFulfilled(true);
  };

  return (
    <div className={styles.childContainer}>
      {user.children ? (
        <section className={styles.userChildren}>
        <div className={styles.childrenMap}>
          {user.children?.map((child) => {
            return (
              <ChildCard key={child.id} child={child} />
            )
          })}
        </div>
        <div><button className={styles.newChildButton} onClick={openNewChildModal}>Add a new child profile</button>
        <Modal
          isOpen={newChildModalIsOpen}
          onRequestClose={closeNewChildModal}
          style={customStyles}
        >
          <h2>Add a new child profile</h2>
          <form className={styles.editForm} onSubmit={handleAddNewChild}>
            <div>
            <label htmlFor="name">Name:</label>
            <input
              name="name"
              type="text"
              value={newChildData.name}
              onChange={handleNewChildDataChange}
            />
            </div>
            <div>
            <label htmlFor="age">Age:</label>
            <input
              name="age"
              type="text"
              value={newChildData.age}
              onChange={handleNewChildDataChange}
            />
            </div>
            <div>
              <select
                placeholder={"gender"}
                onChange={(e) => setNewChildData({
                  ...newChildData,
                  gender: e.target.value,
                  userId: user.id
                })}
                defaultValue="select"
              >
                <option value="select" disabled>Select...</option>
                <option value="girl">girl</option>
                <option value="boy">boy</option>
              </select>
              </div>
              <div>
            <label htmlFor="birthday">Birthday:</label>
            <input
              name="birthday"
              type="date"
              value={newChildData.birthday}
              onChange={handleNewChildDataChange}
              required
            />
            </div>
            <button className={styles.addChildButton} type="submit">Add Child</button>
            <button onClick={closeNewChildModal}>Cancel</button>
          </form>
        </Modal></div>
        </section>
      ) : (
        <section>
          <h2>You haven't created any child profiles yet.</h2>
          <button onClick={openNewChildModal}>Add a new child profile</button>
        </section>
      )}
    </div>
  );
};

export default UserChildren;
