import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching all books.
export const fetchBooks = createAsyncThunk("books/allBooks", async () => {
  try {
    const { data } = await axios.get(`/api/books`);
    return data;
  } catch (err) {
    console.error(err);
  }
});

// Asynchronous thunk for deleting a book by its ID.
export const deleteBooks = createAsyncThunk("deleteBooks", async (id) => {
  const { data } = await axios.delete(`api/books/${id}`);
  return data;
});

// Books slice for managing the state of multiple books.
export const booksSlice = createSlice({
  name: "books",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBooks.fulfilled, (state, action) => {
      return [...state, action.payload];
    });
    builder.addCase(deleteBooks.fulfilled, (state, action) => {
      return {};
    });
  },
});

export const selectBooks = (state) => {
  return state.books;
};

export default booksSlice.reducer;
