import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching a single book by its ID.
export const fetchBook = createAsyncThunk("book", async (id) => {
  try {
    const { data } = await axios.get(`/api/books/${id}`);
    return data;
  } catch (err) {
    console.error(err);
  }
});

// Asynchronous thunk for updating a book.
export const updateBook = createAsyncThunk("updateBook", async (bookData) => {
  try {
    const { data } = await axios.put(`/api/books/${bookData.id}`, bookData);
    return data;
  } catch (err) {
    console.error(err);
  }
});

// Asynchronous thunk for creating a new book.
export const createBook = createAsyncThunk("addBook", async (newBookData) => {
  try {
    const { data } = await axios.post("/api/books", newBookData);
    return data;
  } catch (err) {
    console.error(err);
  }
});

// Book slice for managing book state.
export const bookSlice = createSlice({
  name: "book",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBook.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(updateBook.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(createBook.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const selectBook = (state) => {
  return state.book;
};

export default bookSlice.reducer;
