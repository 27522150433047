import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching children data from the API
export const fetchChildren = createAsyncThunk("allChildren", async () => {
    try {
      const { data } = await axios.get(`/api/children`);
      return data;
    } catch (err) {
      console.error(err);
    }
  });

  // Asynchronous thunk for adding a new child to the database
  export const addChild = createAsyncThunk(
    "children/addChild",
    async ({ name, gender, age, birthday, interests, userId }) => {
      const { data } = await axios.post("/api/children/new", {
        name,
        gender,
        age,
        birthday,
        interests,
        userId,
      });
      return data;
    }
  );

  // Asynchronous thunk for deleting a child from the database
  export const deleteChild = createAsyncThunk("children/deleteChildren", async (id) => {
    try {
    const { data } = await axios.delete(`/api/children/${id}`);
    return data;
    } catch (err) {
      next (err)
    }
  });

  // Slice for handling children state in the Redux store
  export const childrenSlice = createSlice({
    name: "children",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(fetchChildren.fulfilled, (state, action) => {
        //add users to state array
        return action.payload;
      });
      builder.addCase(addChild.fulfilled, (state, action) => {
        state.push(action.payload);
      });
      builder.addCase(deleteChild.fulfilled, (state, { payload }) => 
    state.filter((a) => a.id !== payload.id));
    },
  });

  export const selectChildren = (state) => state.children;

export default childrenSlice.reducer;