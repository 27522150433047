import React from "react";

import Navbar from "../components/navbar/Navbar";
import AppRoutes from "./AppRoutes";
import Footer from "../components/footer/Footer"

const App = () => {
  return (
    <div>
      <Navbar />
      <AppRoutes />
      <Footer />
    </div>
  );
};

export default App;