import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderItems,
  fetchOrderItems,
  selectOrderItem,
  clearItems,
  fetchItems,
  addOrderItems,
} from "../order/orderItemSlice";
import { addEmail } from "../email/emailSlice";
import styles from "../styles/Cart.module.css";
import OrderItemRow from "../orderItem/OrderItemRow";
import axios from "axios";
// UUID generation for user identification
const { v4: uuidv4 } = require("uuid");
const uuid = uuidv4();

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector(selectOrderItem) || [];
  const userId = useSelector((state) => state.auth.me?.id);
  const [email, setEmail] = useState("");

  // useEffect to fetch items when component mounts or userId changes
  useEffect(() => {
    // Fetch items for anonymous users or synchronize local cart with user cart
    if (!userId) {
      dispatch(fetchItems());
    } else {
      const localCart = JSON.parse(localStorage.getItem("order"));
      if (localCart && localCart.length) {
        //Add all local items into user cart
        for (const item of localCart) {
          dispatch(
            addOrderItems({
              id: userId,
              bookId: item.bookId,
              orderQTY: item.orderQTY,
            })
          );
        }
        localStorage.removeItem("order");
        dispatch(fetchOrderItems(userId));
      } else {
        dispatch(fetchOrderItems(userId));
      }
    }
  }, [dispatch, userId]);

  // Email sign-up event handler
  const handleEmailSignUp = (e) => {
    e.preventDefault();
    dispatch(addEmail({ email: email }));
  };

  //Function that calculaets and updates ordertotal based on what elements are in the cart
  const orderTotal = () => {
    let prices = [];
    cart.forEach((orderItem) => {
      prices.push(Number(orderItem.book.price) * orderItem.orderQTY);
    });
    let total = 0.0;
    for (let i = 0; i < prices.length; i++) {
      total += prices[i];
    }
    return total;
  };

  // Estimate tax based on order total
  //THIS IS FOR MVP PURPOSES AND IS NOT ACCURATE TO LOCAL SALES TAX
  const estimateTax = () => {
    const tax = (orderTotal() / 100) * 10;
    return tax.toFixed(2);
  };

  //Function that calculates the complete total
  const total = () => {
    const total = Number(estimateTax()) + orderTotal();
    return total.toFixed(2);
  };

  // Clear cart event handler
  const handleClear = async () => {
    if (!userId) {
      dispatch(clearItems());
    } else {
      await dispatch(deleteOrderItems(userId));
    }
  };

  // Navigation to Stripe Checkout event handler
  const handleNav = async (e) => {
    e.preventDefault();

    //The line items are sent to Stripe to identify order items and price
    const lineItems = cart.map((orderItem) => ({
      price: orderItem.book.stripeId,
      quantity: orderItem.orderQTY,
    }));

    //The bookIds are sent to Stripe to be stored as metadata in the customer's order
    //so that we can attach these ids to the user's Order object that is created through
    //Stripe Webhooks when a purchase is created through Stripe Checkout
    const bookIds = () => {
      let ids = [];
      cart.map((orderItem) => {
        ids.push(orderItem.book.id);
      });
      return ids;
    };

    try {
      //Send info to Stripe Checkout Session API
      //Stripe creates a Checkout Session and the user is forwarded there
      await axios
        .post("/api/stripe/create-checkout-session", {
          cart: lineItems,
          bookIds: bookIds(),
          userId: userId ? userId : uuid,
        })
        .then((res) => {
          window.location.href = res.data.url;
        });
    } catch (error) {
      // Handle errors here
      console.error("Error creating checkout session:", error);
    }
  };

  // JSX for the Cart component
  return (
    <div className={styles.container}>
      {!cart.length ? (
        <div className={styles.emptyContainer}>
          <div className={styles.emptyContent}>
            <h1 className={styles.cartHeaderEmpty}>Your cart is empty.</h1>
            <Link to="/create">
              <h2>Start creating your custom book now!</h2>
            </Link>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.top}>
            <section className={styles.leftSide}>
              <div className={styles.leftHeader}>
                <h1 className={styles.cartHeader}>
                  You have {cart.length} item(s) in your cart.
                </h1>
                <Link to="/create">
                  <p>Continue shopping →</p>
                </Link>
              </div>
              <section className={styles.itemsLeft}>
                <section className={styles.items}>
                  {cart.map((orderItem) => {
                    return (
                      <OrderItemRow
                        key={orderItem.bookId}
                        orderItem={orderItem}
                      />
                    );
                  })}
                </section>
              </section>
            </section>
            <section className={styles.summaryRight}>
              <h1>Order Summary</h1>
              <div className={styles.infoContainer}>
                <div className={styles.sums}>
                  <span>Subtotal:</span>
                  <span>${orderTotal()}</span>
                </div>
                <div className={styles.sums}>
                  <span>Shipping:</span>
                  <span>TBD</span>
                </div>
                <div className={styles.sums}>
                  <span>Estimated Tax:</span>
                  <span>${estimateTax()}</span>
                </div>
                <hr />
                <div className={styles.sums}>
                  <span>
                    <b>Total</b>
                  </span>
                  <span>
                    <b>${total()}</b>
                  </span>
                </div>
              </div>
              <div className={styles.buttons}>
                <button onClick={handleNav}>Proceed to Checkout</button>
                <button onClick={handleClear}>Clear Cart</button>
              </div>
            </section>
          </div>
          <section className={styles.email}>
            <div className={styles.emailTop}>
              <div className={styles.owlEmail}>
                <img
                  src="/images/user-owl-icons/owl-logo.png"
                  className={styles.chatIcon}
                />
                <div>
                  <h2>Sign Up for Email</h2>
                  <p>
                    Sign up for our email list for exclusive deals and offers!
                  </p>
                </div>
              </div>
              <form className={styles.emailForm} onSubmit={handleEmailSignUp}>
                <label htmlFor="email">Email Address*</label>
                <input
                  className={styles.input}
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className={styles.button}>
                  Submit
                </button>
              </form>
            </div>
            <p className={styles.emailDisc}>
              *By signing up, you will receive MyCustomStories offers,
              promotions, and other commercial messages. You may unsubscribe at
              any time.
            </p>
          </section>
        </div>
      )}
    </div>
  );
};

export default Cart;
