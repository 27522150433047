import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching a story by its ID.
export const fetchStoryAsync = createAsyncThunk("fetchStory", async (id) => {
  try {
    const { data } = await axios.get(`/api/create/story/${id}`);
    return data;
  } catch (error) {
    console.error(error);
  }
});

// Asynchronous thunk for updating an existing story.

export const updateStoryAsync = createAsyncThunk(
  "updateStory",
  async ({ id, bookId }) => {
    const { data } = await axios.put(`/api/create/story/edit/${id}`, {
      bookId,
    });
    return data;
  }
);

// Slice for handling a single story's state in the Redux store.
export const storySlice = createSlice({
  name: "story",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoryAsync.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(updateStoryAsync.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const selectStory = (state) => state.story;

export default storySlice.reducer;
