import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Slideshow from "../../slideshow/Slideshow";
import styles from "../../styles/BookSelection.module.css";

// BookType: A component that allows users to select the type of book they wish to create.
// It provides various options such as Paperback, Hardcover, Digital PDF, and Hardcover Sleeve.
// Additionally, it handles the selection of images and bios for the Hardcover Sleeve option.

const BookType = ({
  showRenderSleeveOptions,
  setShowRenderSleeveOptions,
  setShowRenderColorChoice,
  bookTypeChoice,
  setBookTypeChoice,
  presignedUrls,
  setPrice,
  setStripeId,
  sleeveBio,
  setSleeveBio,
  sleeveImgIndex,
  setSleeveImgIndex,
  customBio,
  setCustomBio,
  generatedBio,
  setGeneratedBio,
  choiceDisabled,
  dedicationName
}) => {
  const name = JSON.parse(localStorage.getItem("name")) || "";
  const age = JSON.parse(localStorage.getItem("age")) || "";
  const gender = JSON.parse(localStorage.getItem("gender")) || "";
  const interest = JSON.parse(localStorage.getItem("interest")) || "";
  const childName = JSON.parse(localStorage.getItem("childName")) || null;
  const childAge = JSON.parse(localStorage.getItem("childName")) || null;
  const childGender = JSON.parse(localStorage.getItem("childName")) || null;
  const [details, setDetails] = useState("");

  const [sleeveImg, setSleeveImg] = useState(null);
  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);
  const [bioModalIsOpen, setBioModalIsOpen] = useState(false);
  const [imgModalConfirmError, setImgModalConfirmError] = useState(false);
  const [bioModalConfirmError, setBioModalConfirmError] = useState(false);

  //These are the images for the slideshows that display example images for each BookType option
  const portraitPaperback = [
    "/images/marketing_books/mockup-of-a-paperback-book1.png",
    "/images/marketing_books/mockup-featuring-a-book-surrounded-by-wildflowers-m18732.png",
    "/images/marketing_books/book-mockup-of-a-girl-playing-with-a-magnifying-glass-in-her-living-room-m26924.png",
    "/images/marketing_books/boy-reading-a-book-mockup-at-the-studio-a19205.png",
  ];

  const PDF = [
    "/images/marketing_books/mockup-featuring-an-ipad-lying-on-a-picnic-wooden-table-m12447-r-el2.png",
    "/images/marketing_books/black-iphone-4-vertical-park.png",
    "/images/marketing_books/mockup-of-an-iphone-placed-on-top-of-a-tablet-m22220-r-el2.png",
  ];

  const portraitHardcover = [
    "/images/marketing_books/mockup-of-a-paperback-book-in-a-plain-setting-33643.png",
    "/images/marketing_books/mockup-featuring-a-book-surrounded-by-wildflowers.png",
    "/images/marketing_books/book-mockup-of-a-child-on-a-sofa-23723.png",
    "/images/marketing_books/book-mockup-of-a-girl-playing-with-a-magnifying-glass-in-her-living-room-m26924.png",
  ];

  const portraitSleeve = [
    "/images/marketing_books/mockup-of-an-open-hard-book-cover-with-a-sleeve-848-el.png",
    "/images/marketing_books/mockup-of-a-paperback-book-in-a-plain-setting-33643.png",
    "/images/marketing_books/mockup-featuring-a-book-surrounded-by-wildflowers.png",
    "/images/marketing_books/book-mockup-of-a-child-on-a-sofa-23723.png",
    "/images/marketing_books/book-mockup-of-a-girl-playing-with-a-magnifying-glass-in-her-living-room-m26924.png",
  ];

  const landscapeHardcover = [
    "/images/marketing_books/mockup-of-a-horizontal-paperback-book-30942.png",
    "/images/marketing_books/mockup-of-a-horizontal-book-placed-next-to-a-stuffed-animal-30943.png",
    "/images/marketing_books/mockup-of-a-horizontal-book-on-a-customizable-backdrop-33397.png",
  ];

  const landscapeSleeve = [
    "/images/marketing_books/mockup-of-an-open-hard-book-cover-with-a-sleeve-848-el.png",
    "/images/marketing_books/mockup-of-a-horizontal-paperback-book-30942.png",
    "/images/marketing_books/mockup-of-a-horizontal-book-placed-next-to-a-stuffed-animal-30943.png",
    "/images/marketing_books/mockup-of-a-horizontal-book-on-a-customizable-backdrop-33397.png",
  ];

  useEffect(() => {
    setBioModalConfirmError(false);
  }, [customBio]);

// Modal styling
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // Functions to control the opening and closing of image and bio modals.
  const openImgModal = () => {
    setImgModalIsOpen(true);
  };

  const closeImgModal = () => {
    setImgModalIsOpen(false);
  };

  const openBioModal = () => {
    setBioModalIsOpen(true);
  };

  const closeBioModal = () => {
    setBioModalIsOpen(false);
  };

  // Handlers for confirming the selection of sleeve image and bio.
  const handleConfirmSleeveImg = (e) => {
    e.preventDefault();
    if (!sleeveImg) {
      setImgModalConfirmError(true);
    } else {
      setImgModalConfirmError(false);
      closeImgModal();
    }
  };

  // Function to calculate the price of the book based on its type.
  const handleConfirmSleeveBio = (e) => {
    e.preventDefault();
    if (customBio) {
      if (sleeveBio === "") {
        setBioModalConfirmError(true);
      } else {
        setBioModalConfirmError(false);
        closeBioModal();
      }
    } else {
      if (generatedBio === "") {
        setBioModalConfirmError(true);
      } else {
        setBioModalConfirmError(false);
        closeBioModal();
      }
    }
  };

  // Function to calculate the price of the book based on its type.
  const calculatePrice = (bookTypeChoice) => {
    switch (bookTypeChoice) {
      case "portraitPaperback":
        return 50.0;
      case "portraitHardcover":
      case "landscapeHardcover":
        return 70.0;
      case "pdfOnly":
        return 30.0;
      case "portraitSleeve":
      case "landscapeSleeve":
        return 80.0;
      default:
        return 0.0; // Default to 0 or handle the case as needed
    }
  };

  // Function to determine the Stripe ID based on the selected book type.
  const calculateStripeId = (bookTypeChoice) => {
    //override
    // return "price_1NrkscAb1EzBrlBEqYmSEdLc"; // dev mode

    switch (bookTypeChoice) {
      case "portraitPaperback":
        return "price_1O20EvAb1EzBrlBEWlXcCEDs";
      case "portraitHardcover":
        return "price_1O20GZAb1EzBrlBEi6Eim6Yz";
      case "landscapeHardcover":
        return "price_1O20vgAb1EzBrlBEnFcQbJli";
      case "pdfOnly":
        return "price_1O20seAb1EzBrlBEgRMaCtYn";
      case "portraitSleeve":
        return "price_1O20r8Ab1EzBrlBEytKeyRks";
      case "landscapeSleeve":
        return "price_1O20s8Ab1EzBrlBEWr3QfJON";
      default:
        return ""; // Default to 0 or handle the case as needed
    }
  };

  // Function to handle the change in book type selection and update relevant states.
  const handleOptionChange = (event) => {
    const selectedBookType = event.target.value;
    setBookTypeChoice(selectedBookType);
    setShowRenderColorChoice(true);

    const selectedPrice = calculatePrice(selectedBookType);
    setPrice(selectedPrice);

    const selectedStripeId = calculateStripeId(selectedBookType);
    setStripeId(selectedStripeId);

    if (
      selectedBookType === "portraitSleeve" ||
      selectedBookType === "landscapeSleeve"
    ) {
      setShowRenderSleeveOptions(true);
    } else {
      setShowRenderSleeveOptions(false);
    }
  };

  // Function to generate or submit a custom biography for the book.
  const handleSubmitBio = async (e) => {
    e.preventDefault();
    try {
      function toTitleCase(str) {
        return str
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }

      let generateParams;
      if (childName && childAge) {
        if (interest) {
          generateParams = {
            age: childAge,
            gender: childGender,
            name: toTitleCase(childName),
            details: details,
            interest: interest,
          };
        } else {
          generateParams = {
            age: childAge,
            gender: childGender,
            name: toTitleCase(childName),
            details: details,
          };
        }
      } else {
        if (interest) {
          generateParams = {
            age: age,
            gender: gender.toLowerCase(),
            name: toTitleCase(name),
            details: details,
            interest: interest,
          };
        } else {
          generateParams = {
            age: age,
            gender: gender.toLowerCase(),
            name: toTitleCase(name),
            details: details,
          };
        }
      }

      const response = await fetch("/api/generate/text/bio", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(generateParams),
      });

      if (!response.body) {
        throw new Error("Stream not supported");
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        setGeneratedBio((prev) => prev + value);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Render JSX
  return (
    <section className={styles.bookType}>
      <div className={styles.header}>
        <h1>Choose a style of book</h1>
        <h2>
          All of our books are made with premium color ink printed onto thick,
          high quality paper.
        </h2>
        <h2>
          All physical book orders (Paperback, Hardcover, and Hardcover Sleeve)
          come with a complementary digital PDF version of your book.
        </h2>
      </div>
      <section className={styles.topRow}>
        <div className={styles.paperback}>
          <h2>Paperback</h2>
          <div className={styles.books}>
            <div className={styles.book}>
              <label>
                <input
                  type="radio"
                  name="options"
                  value="portraitPaperback"
                  checked={bookTypeChoice === "portraitPaperback"}
                  onChange={handleOptionChange}
                />
                <div className={styles.titlePrice}>
                  <p>US Letter Portrait (8.5"x11")</p>
                  <b>$50.00</b>
                </div>
              </label>
              <Slideshow imageArray={portraitPaperback} />
            </div>
          </div>
        </div>
        <div className={styles.hardcover}>
          <h2>Hardcover</h2>
          <div className={styles.books}>
            <div
              className={
                choiceDisabled
                  ? `${styles.disabled} ${styles.book}`
                  : `${styles.book}`
              }
            >
              <label>
                <input
                  type="radio"
                  name="options"
                  value="portraitHardcover"
                  checked={bookTypeChoice === "portraitHardcover"}
                  onChange={handleOptionChange}
                  disabled={choiceDisabled}
                />
                <div className={styles.titlePrice}>
                  <p>US Letter Portrait (8.5"x11")</p>
                  <b>$70.00</b>
                </div>
              </label>
              <Slideshow imageArray={portraitHardcover} />
            </div>
            <div
              className={
                choiceDisabled
                  ? `${styles.disabled} ${styles.book}`
                  : `${styles.book}`
              }
            >
              <label>
                <input
                  type="radio"
                  name="options"
                  value="landscapeHardcover"
                  checked={bookTypeChoice === "landscapeHardcover"}
                  onChange={handleOptionChange}
                  disabled={choiceDisabled}
                />
                <div className={styles.titlePrice}>
                  <p>US Letter Landscape (11"x8.5")</p>
                  <b>$70.00</b>
                </div>
              </label>
              <Slideshow imageArray={landscapeHardcover} />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.bottomRow}>
        <div className={styles.pdf}>
          <h2>Digital PDF</h2>
          <div className={styles.books}>
            <div className={styles.book}>
              <label>
                <input
                  type="radio"
                  name="options"
                  value="pdfOnly"
                  checked={bookTypeChoice === "pdfOnly"}
                  onChange={handleOptionChange}
                />
                <div className={styles.titlePrice}>
                  <p>PDF Only</p>
                  <b>$30.00</b>
                </div>
              </label>
              <Slideshow imageArray={PDF} />
            </div>
          </div>
        </div>
        <div className={styles.sleeve}>
          <h2>Hardcover Sleeve</h2>
          <section className={styles.sleeveSections}>
            <div className={styles.books}>
              <div
                className={
                  choiceDisabled
                    ? `${styles.disabled} ${styles.book}`
                    : `${styles.book}`
                }
              >
                <label>
                  <input
                    type="radio"
                    name="options"
                    value="portraitSleeve"
                    checked={bookTypeChoice === "portraitSleeve"}
                    onChange={handleOptionChange}
                    disabled={choiceDisabled}
                  />
                  <div className={styles.titlePrice}>
                    <p>US Letter Portrait Sleeve (8.5"x11")</p>
                    <b>$85.00</b>
                  </div>
                </label>
                <Slideshow imageArray={portraitSleeve} />
              </div>
              <div
                className={
                  choiceDisabled
                    ? `${styles.disabled} ${styles.book}`
                    : `${styles.book}`
                }
              >
                <label>
                  <input
                    type="radio"
                    name="options"
                    value="landscapeSleeve"
                    checked={bookTypeChoice === "landscapeSleeve"}
                    onChange={handleOptionChange}
                    disabled={choiceDisabled}
                  />
                  <div className={styles.titlePrice}>
                    <p>US Letter Landscape Sleeve (11"x8.5")</p>
                    <b>$85.00</b>
                  </div>
                </label>
                <Slideshow imageArray={landscapeSleeve} />
              </div>
            </div>
            {showRenderSleeveOptions && (
              <section className={styles.sleeveOptions}>
                <p className={styles.sleeveOptionsHeader}>
                  <b>The Hardcover Sleeve</b> option features a{" "}
                  <b>Biography Section </b>
                  about {dedicationName} on the back inside sleeve and is meant
                  to serve as a way to encapsulate who they are and what they
                  love at this precious time in their life.
                </p>
                <div className={styles.sleeveBioImg}>
                  <div className={styles.sleeveBio}>
                    <p>Please provide a biography about {dedicationName}.</p>
                    <button className={styles.button} onClick={openBioModal}>
                      Open Editor
                    </button>
                  </div>
                  <div className={styles.sleevePic}>
                    <p>Please select an image of {dedicationName}.</p>
                    <button className={styles.button} onClick={openImgModal}>
                      Select
                    </button>
                    {sleeveImg ? (
                      <div>
                        <p>
                          <b>Sleeve image:</b>
                        </p>
                        <p>Image number {sleeveImgIndex + 1}</p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div>
                      <Modal
                        isOpen={bioModalIsOpen}
                        onRequestClose={closeBioModal}
                        style={customStyles}
                      >
                        <div className={styles.bioModal}>
                          <h2>{dedicationName}'s Biography</h2>
                          {customBio ? (
                            <div className={styles.bioCustom}>
                              <textarea
                                className={styles.bioTextField}
                                value={sleeveBio}
                                placeholder={`${dedicationName} is a smart, creative, and kind kid whose infectious energy and bubbly personality brightens our lives every day...`}
                                onChange={(e) => setSleeveBio(e.target.value)}
                              />
                              <button
                                className={styles.confirmButton}
                                onClick={handleConfirmSleeveBio}
                              >
                                Confirm
                              </button>
                              <button
                                className={styles.cancelButton}
                                onClick={() => {
                                  setSleeveBio("");
                                  closeBioModal();
                                }}
                              >
                                X
                              </button>
                              {bioModalConfirmError ? (
                                <p>
                                  You must enter a biography before confirming.
                                </p>
                              ) : null}
                              <button
                                className={styles.switchButton}
                                onClick={() => {
                                  setCustomBio(false);
                                }}
                              >
                                Switch to Use Our Biography Prompt
                              </button>
                            </div>
                          ) : (
                            <div className={styles.bioPrompt}>
                              <div className={styles.bioPromptInput}>
                                <label htmlFor="details">
                                  <b>
                                    Share some details about {dedicationName}:
                                  </b>
                                </label>
                                <p>
                                  Which adjectives describe them best? What
                                  grade are they in? Do they have additional
                                  interests, proud achievements, etc? Who are
                                  some of their loved ones or best friends?
                                </p>
                                <textarea
                                  name="details"
                                  type="text"
                                  value={details}
                                  placeholder={`${dedicationName} is charismatic, creative, and a great sibling!`}
                                  onChange={(e) => setDetails(e.target.value)}
                                />
                                <button
                                  className={styles.generateButton}
                                  onClick={handleSubmitBio}
                                >
                                  Generate Biography
                                </button>
                              </div>
                              <textarea
                                className={styles.bioTextField}
                                value={generatedBio}
                                onChange={(e) =>
                                  setGeneratedBio(e.target.value)
                                }
                              />
                              <button
                                className={styles.confirmButton}
                                onClick={handleConfirmSleeveBio}
                              >
                                Confirm
                              </button>
                              <button
                                className={styles.cancelButton}
                                onClick={() => {
                                  setSleeveBio("");
                                  closeBioModal();
                                }}
                              >
                                X
                              </button>
                              {bioModalConfirmError ? (
                                <p>
                                  You must enter a biography before confirming.
                                </p>
                              ) : null}
                              <button
                                className={styles.switchButton}
                                onClick={() => {
                                  setCustomBio(true);
                                }}
                              >
                                Switch to Write a Custom Biography
                              </button>
                            </div>
                          )}
                        </div>
                      </Modal>
                      <Modal
                        isOpen={imgModalIsOpen}
                        onRequestClose={closeImgModal}
                        style={customStyles}
                      >
                        <div className={styles.imgModal}>
                          <h2>Select an Image</h2>
                          <div className={styles.sleeveBioPics}>
                            {presignedUrls?.map((imageUrl, index) => {
                              return (
                                <label key={index}>
                                  <input
                                    type="radio"
                                    name="bioImg"
                                    value={imageUrl}
                                    checked={sleeveImg === `${imageUrl}`}
                                    onChange={() => {
                                      setSleeveImg(imageUrl);
                                      setSleeveImgIndex(index);
                                    }}
                                  />
                                  <img
                                    className={styles.sleeveBioPic}
                                    src={imageUrl}
                                  />
                                </label>
                              );
                            })}
                          </div>
                          <div className={styles.buttons}>
                            <button
                              className={styles.button}
                              onClick={handleConfirmSleeveImg}
                            >
                              Confirm
                            </button>
                            <button
                              className={styles.button}
                              onClick={() => {
                                setSleeveImg(null);
                                closeImgModal();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          {imgModalConfirmError ? (
                            <p>You must select a photo before confirming.</p>
                          ) : null}
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </section>
        </div>
      </section>
    </section>
  );
};

export default BookType;
