// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N_9mtSkN1I_bcJWvcS1Z {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 8rem;
}

.urIuXs21fHLcNGMUbUl9 {
    max-width: 8rem;
}

@media only screen and (max-width: 768px) {
.N_9mtSkN1I_bcJWvcS1Z h1 {
    font-size: 1.5rem;
}
.N_9mtSkN1I_bcJWvcS1Z p {
    font-size: 1rem;
}
.urIuXs21fHLcNGMUbUl9 {
    max-width: 5rem;
}
}`, "",{"version":3,"sources":["webpack://./client/components/styles/Confirmation.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA","sourcesContent":[".container {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-direction: column;\n    gap: 2.5rem;\n    margin-top: 8rem;\n}\n\n.owl {\n    max-width: 8rem;\n}\n\n@media only screen and (max-width: 768px) {\n.container h1 {\n    font-size: 1.5rem;\n}\n.container p {\n    font-size: 1rem;\n}\n.owl {\n    max-width: 5rem;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `N_9mtSkN1I_bcJWvcS1Z`,
	"owl": `urIuXs21fHLcNGMUbUl9`
};
export default ___CSS_LOADER_EXPORT___;
