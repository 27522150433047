import React from "react";
import { CirclePicker } from "react-color";
import styles from "../../styles/BookSelection.module.css";

//CoverColorFont: a component that allows users to select the color and font
//that they wish to be used in their book's cover design

const CoverColorFont = ({
    showRenderColorChoice,
    showRenderFontChoice,
    setShowRenderFontChoice,
    setShowRenderDedication,
    coverColorChoice,
    setCoverColorChoice,
    coverFontChoice,
    setCoverFontChoice
}) => {

    // Function to handle font change
  const handleFontChange = (event) => {
    setCoverFontChoice(event.target.value);
    setShowRenderDedication(true);
  };

    // Function to handle color change
  const handleChangeComplete = (newColor) => {
    setCoverColorChoice(newColor.hex);
    setShowRenderFontChoice(true);
  };

  return (
    <>
      {showRenderColorChoice && (
        <section className={styles.color}>
          <div className={styles.circles}>
            <h2 className={styles.colorHeader}>
              Pick a color for your book cover:
            </h2>
            <CirclePicker
              color={coverColorChoice}
              onChangeComplete={handleChangeComplete}
            />
          </div>
          <h2 className={styles.or}>OR</h2>
          <div className={styles.patterns}>
            <h2>Pick a pattern:</h2>
            <div className={styles.backgrounds}>
              <img
                src="/images/patterns/background-blue-streaks.png"
                className={
                  coverColorChoice === "blue-streaks"
                    ? styles.selectedImage
                    : ""
                }
                onClick={() => {
                  setCoverColorChoice("blue-streaks");
                  setShowRenderFontChoice(true);
                }}
              />
              <img
                src="/images/patterns/background-old-leather.png"
                className={
                  coverColorChoice === "old-leather" ? styles.selectedImage : ""
                }
                onClick={() => {
                  setCoverColorChoice("old-leather");
                  setShowRenderFontChoice(true);
                }}
              />
              <img
                src="/images/patterns/background-galaxy.png"
                className={
                  coverColorChoice === "galaxy" ? styles.selectedImage : ""
                }
                onClick={() => {
                  setCoverColorChoice("galaxy");
                  setShowRenderFontChoice(true);
                }}
              />
              <img
                src="/images/patterns/background-pink-dream.png"
                className={
                  coverColorChoice === "pink-dream" ? styles.selectedImage : ""
                }
                onClick={() => {
                  setCoverColorChoice("pink-dream");
                  setShowRenderFontChoice(true);
                }}
              />
              <img
                src="/images/patterns/background-purple-sparkle.png"
                className={
                  coverColorChoice === "purple-sparkle"
                    ? styles.selectedImage
                    : ""
                }
                onClick={() => {
                  setCoverColorChoice("purple-sparkle");
                  setShowRenderFontChoice(true);
                }}
              />
              <img
                src="/images/patterns/background-snowflakes.png"
                className={
                  coverColorChoice === "snowflakes" ? styles.selectedImage : ""
                }
                onClick={() => {
                  setCoverColorChoice("snowflakes");
                  setShowRenderFontChoice(true);
                }}
              />
            </div>
          </div>
        </section>
      )}
      {showRenderFontChoice && (
        <section className={styles.font}>
          <h2>Pick a font for your book cover:</h2>
          <div className={styles.fontPics}>
            <label>
              <input
                type="radio"
                name="fonts"
                value="angelina"
                checked={coverFontChoice === "angelina"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-angelina.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="architype-rubette"
                checked={coverFontChoice === "architype-rubette"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-architype-rubette.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="arial"
                checked={coverFontChoice === "arial"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-arial.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="berkshire-swash"
                checked={coverFontChoice === "berkshire-swash"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-berkshire-swash.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="buffalo"
                checked={coverFontChoice === "buffalo"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-buffalo.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="citadel-script"
                checked={coverFontChoice === "citadel-script"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-citadel-script.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="comic-sans"
                checked={coverFontChoice === "comic-sans"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-comic-sans.png"
              />
            </label>
            <label>
              <input
                type="radio"
                name="fonts"
                value="libre-baskerville"
                checked={coverFontChoice === "libre-baskerville"}
                onChange={handleFontChange}
              />
              <img
                className={styles.fontPic}
                src="/images/fonts/font-libre-baskerville.png"
              />
            </label>
          </div>
        </section>
      )}
    </>
  );
};

export default CoverColorFont;