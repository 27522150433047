import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

//Imported reducers
import authReducer from '../components/auth/authSlice';
import allUsersReducer from '../components/user/usersSlice';
import singleUserReducer from '../components/user/userSlice';
import childrenReducer from '../components/child/childrenSlice';
import childReducer from '../components/child/childSlice';
import storiesReducer from '../components/create/storiesSlice';
import storyReducer from '../components/create/storySlice';
import pageTextsReducer from '../components/create/pageTextsSlice';
import bookReducer from '../components/book/bookSlice';
import booksReducer from '../components/book/booksSlice';
import orderItemReducer from '../components/order/orderItemSlice';
import ordersReducer from '../components/order/ordersSlice';
import orderReducer from '../components/order/orderSlice';
import emailReducer from '../components/email/emailSlice';

const store = configureStore({
  reducer: { 
    auth: authReducer,
    users: allUsersReducer,
    singleUser: singleUserReducer,
    child: childReducer,
    children: childrenReducer,
    stories: storiesReducer,
    story: storyReducer,
    pageTexts: pageTextsReducer,
    book: bookReducer,
    books: booksReducer,
    orderItem: orderItemReducer,
    orders: ordersReducer,
    order: orderReducer,
    email: emailReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
export * from '../components/auth/authSlice';