import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { createStoryAsync } from "../storiesSlice";
import PropagateLoader from "react-spinners/PropagateLoader";
import styles from "../../styles/Create.module.css";

/*
  RenderPages component is responsible for displaying the story pages generated from the ChatGPT4 API.
  It allows users to edit these pages, give the story a name, and save or regenerate the story.
 */

const RenderPages = ({
  response,
  childId,
  setShowRenderUploadImages,
  id,
  childName,
  name,
  handleSubmit,
  pageContents,
  setPageContents,
  storyName,
  setStoryName,
  setStoryId,
  loading,
  setLoading,
  confirmedStory,
  setConfirmedStory,
}) => {
  const dispatch = useDispatch();

    // Effect to split the API response into individual pages and set them in state
  useEffect(() => {
    const pageDelimiter = /PAGE_BREAK\s*\d+|PAGE_BREAK_\d+|PAGE_BREAK\s*\d+:|PAGE_BREAK\s+/;
    let pageTexts = response.split(pageDelimiter).map((page) => page.trim());

    if (pageTexts[0] === "") {
      pageTexts = pageTexts.slice(1);
    }
    setPageContents(pageTexts);
  }, [response]);

  /* 
  handleCreateStory handles the story finalization process.
  It creates the Story object and the associated individual PageText objects
  and uploads each page's content to a server.
  */
  const handleCreateStory = async () => {
    try {
      if (pageContents.length > 0) {
        const createStoryParams = {
          name: storyName,
        };

        if (childId) {
          createStoryParams.childId = childId;
        }

        if (typeof id === "string" || isNaN(id)) {
          createStoryParams.guestId = id;
        } else {
          createStoryParams.userId = id;
        }

        if (childName) {
          createStoryParams.childName = childName;
        } else {
          createStoryParams.childName = name;
        }

        const story = await dispatch(createStoryAsync(createStoryParams));
        const storyId = story.payload.id;
        setStoryId(storyId);

        // Create an array of promises for uploading text files
        const uploadPromises = pageContents.map(async (page, index) => {
          const textBlob = new Blob([page], { type: "text/plain" });
          const formData = new FormData();
          formData.append(
            "textFile",
            textBlob,
            `story-${storyId}-page-${index + 1}.txt`
          );
          formData.append("pageNumber", index + 1);
          formData.append("storyId", storyId);

          // Send the text file to your backend for processing and S3 upload
          await axios.post("/api/awsS3/upload/pageTexts", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        });

        // Wait for all text file uploads to complete
        await Promise.all(uploadPromises);

        setShowRenderUploadImages(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //handlePageContentChange updates the content of a specific story page as a user types in the
  //editable textareas where the story pages are generated

  const handlePageContentChange = (index, newValue) => {
    const newArray = [...pageContents];

    // Update the specific element at the given index
    newArray[index] = newValue;

    // Update the state with the modified array
    setPageContents(newArray);
  };

  return (
    <div className={styles.subContainerOwl}>
      <div className={styles.chatContainer}>
        <img
          src="/images/user-owl-icons/owl-logo.png"
          className={styles.chatIcon}
        />
        <section className={styles.storyContainer}>
          {confirmedStory ? (
            <div>
              <h2 className={styles.confirmedStoryName}>{storyName}</h2>
              <section className={styles.confirmedStoryPages}>
                {pageContents.map((pageContent, index) => {
                  // Use pageContents here
                  return (
                    <div key={index + 1} className={styles.page}>
                      <h2>Page {index + 1}:</h2>
                      <div className={styles.pageText}>
                        <p>{pageContent}</p>
                      </div>
                    </div>
                  );
                })}
              </section>
            </div>
          ) : (
            <div>
              <section className={styles.pages}>
                {pageContents.map((pageContent, index) => {
                  // Use pageContents here
                  return (
                    <div key={index + 1} className={styles.page}>
                      <h2>Page {index + 1}:</h2>
                      <div className={styles.pageText}>
                        <textarea
                          value={pageContent}
                          onChange={(e) =>
                            handlePageContentChange(index, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </section>
              <form className={styles.renderPagesButtons}>
                <input
                  className={styles.storyNameInput}
                  type="text"
                  placeholder="Give Your Story a Name!"
                  value={storyName}
                  onChange={(e) => setStoryName(e.target.value)}
                />
                <div className={styles.buttons}>
                  <button
                    type="button"
                    onClick={() => {
                      handleCreateStory();
                      setConfirmedStory(true);
                    }}
                    className={loading ? `${styles.renderPageButton} ${styles.disabled}` : `${styles.renderPageButton}`}
                  >
                    Confirm Story
                  </button>
                  <p className={styles.renderOr}>or</p>
                  <button
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                    className={styles.renderPageButton}
                  >
                    Write me a new one
                  </button>
                </div>
              </form>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default RenderPages;
