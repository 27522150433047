import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { editChild } from "./childSlice";
import { deleteChild } from "./childrenSlice";
import styles from "../styles/UserAccount.module.css";

// ChildCard component for displaying and editing a child's profile

const ChildCard = ({ child }) => {
  const [edit, setEdit] = useState(false);
  const [childName, setChildName] = useState(child.name || "");
  const [childAge, setChildAge] = useState(child.age || "");
  const [childBirthday, setChildBirthday] = useState(child.birthday || "");
  const [childGender, setChildGender] = useState(child.gender || "");
  const [childInterests, setChildInterests] = useState(child.interests || []);
  const [newInterest, setNewInterest] = useState("");
  const [addInterest, setAddInterest] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const dispatch = useDispatch();

    // Custom styles for the modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // Functions for handling modal open and close
  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  // Function to handle adding a new interest
  const handleAddInterest = async (e) => {
    e.preventDefault();
    const updatedInterests = [...childInterests, newInterest];
    setChildInterests(updatedInterests);
    await dispatch(
      editChild({
        id: child.id,
        name: childName,
        age: childAge,
        birthday: childBirthday,
        gender: childGender,
        interests: updatedInterests, // Use the updated array here
      })
    );
    setNewInterest("");
  };

  // Function to handle deleting an interest
  const handleDeleteInterest = async (indexToDelete) => {
    const updatedInterests = [...childInterests];
    updatedInterests.splice(indexToDelete, 1); // Remove the interest at the specified index
    setChildInterests(updatedInterests); // Update the state with the new interests list
    await dispatch(
      editChild({
        id: child.id,
        name: childName,
        age: childAge,
        birthday: childBirthday,
        gender: childGender,
        interests: childInterests, // Use the updated array here
      })
    );
  };

  // Function to handle updating the child's profile
  const handleUpdateChild = async (e) => {
    e.preventDefault();
    await dispatch(
      editChild({
        id: child.id,
        name: childName,
        age: childAge,
        birthday: childBirthday,
        gender: childGender,
        interests: childInterests,
      })
    );
    window.location.reload();
    setEdit(false);
  };

  // Function to handle deleting the child's profile
  const handleDeleteChild = async (e) => {
    e.preventDefault();
    await dispatch(deleteChild(child.id));
    closeDeleteModal();
    window.location.reload();
  };

  return (
    <section className={styles.childProfileContainer}>
      <div key={child.id}>
        {edit ? (
          <div>
            <form className={styles.editChildForm} onSubmit={handleUpdateChild}>
              <div className={styles.editChildFormSection}>
                <label htmlFor="childName">First Name:</label>
                <input
                  name="childName"
                  type="text"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                />
              </div>
              <div className={styles.editChildFormSection}>
                <label htmlFor="childAge">Age:</label>
                <input
                  name="childAge"
                  type="text"
                  value={childAge}
                  onChange={(e) => setChildAge(e.target.value)}
                />
              </div>
              <div className={styles.editChildFormSection}>
                <p>Gender:</p>
                <select
                  placeholder={child.gender ? child.gender : "gender"}
                  onChange={(e) => setChildGender(e.target.value)}
                >
                  <option value="girl">girl</option>
                  <option value="boy">boy</option>
                </select>
              </div>
              <div className={styles.editChildFormSection}>
                <label htmlFor="childBirthday">Birthday:</label>
                <input
                  name="childBirthday"
                  type="date"
                  value={childBirthday}
                  onChange={(e) => setChildBirthday(e.target.value)}
                />
              </div>
              <div className={styles.editChildFormSection}>
                <p>Interests:</p>
                {childInterests.map((interest, index) => {
                  return (
                    <div className={styles.interestEdit} key={index}>
                      <p>{interest}</p>
                      <button
                        className={styles.deleteEditButton}
                        onClick={() => handleDeleteInterest(index)}
                      >
                        x
                      </button>
                    </div>
                  );
                })}
                {addInterest ? (
                  <div>
                    <input
                      type="text"
                      value={newInterest}
                      onChange={(e) => setNewInterest(e.target.value)}
                    />
                    <button className={styles.checkMarkButton} onClick={handleAddInterest}>
                      <span className="material-symbols-outlined">check</span>
                    </button>
                  </div>
                ) : null}
                <button
                  className={styles.addInterestButton}
                  onClick={() => setAddInterest(true)}
                >
                  +
                </button>
                <hr />
                <button className={styles.userInfoButton} type="submit">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <h2>{child.name}</h2>
            <p>Age: {child.age}</p>
            <p>Birthday: {child.birthday}</p>
            <p>Gender: {child.gender}</p>
            <div>
              <p>Interests:</p>
              <ul>
                {childInterests.map((interest, index) => (
                  <li className={styles.interest} key={index}>
                    {interest}
                  </li>
                ))}
              </ul>
              {addInterest ? (
                <div className={styles.interestInput}>
                  <input
                    type="text"
                    value={newInterest}
                    onChange={(e) => setNewInterest(e.target.value)}
                  />
                  <button onClick={handleAddInterest}>
                    <span className="material-symbols-outlined">check</span>
                  </button>
                </div>
              ) : null}
              <button
                className={styles.addInterestButton}
                onClick={() => setAddInterest(true)}
              >
                +
              </button>
            </div>
            <hr />
            <div className={styles.buttons}>
              <button
                className={styles.userInfoButton}
                type="button"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
              <button
                className={styles.userInfoCancelButton}
                onClick={openDeleteModal}
              >
                Delete
              </button>
            </div>
            <Modal
              isOpen={deleteModalIsOpen}
              onRequestClose={closeDeleteModal}
              style={customStyles}
              className={styles.deleteChildModal}
            >
              <h2>Are you sure you want to delete {child.name}'s profile?</h2>
              <div>
                <button onClick={closeDeleteModal}>Nevermind!</button>
                <button onClick={handleDeleteChild}>Yes, delete.</button>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </section>
  );
};

export default ChildCard;
