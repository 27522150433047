import React from "react";
import AllOrders from "./components/AllOrders";
import ActiveOrders from "./components/ActiveOrders";
import PastOrders from "./components/PastOrders";
import styles from "../styles/Admin.module.css"

//The Admin page is where the admins of MCS can view all of their orders
//There are separate components in the /client/components/admin/components folder to display All Orders, Active Orders, and Past Orders

const AdminPage = () => {
return (
    <section className={styles.container}>
        <AllOrders />
        <ActiveOrders />
        <PastOrders />
    </section>
)

};

export default AdminPage;