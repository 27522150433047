import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/OrderItemRow.module.css";
import { editOrderItems, editItems } from "../order/orderItemSlice";

/**
 * Component for a row in the cart
 * @component shows the amount of bookss in the cart
 * @receives the orderitem object as a prop
 */
const OrderItemCard = ({ orderItem }) => {
  const book = orderItem.book;
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(orderItem.orderQTY);
  const id = useSelector((state) => state.auth.me?.id);
  // sets quantity state based on the prop passed

  const bookType = () => {
    if (book.bookType === "portraitPaperback") {
      return "Portrait Paperback";
    } else if (book.bookType === "portraitHardcover") {
      return "Portrait Hardcover";
    } else if (book.bookType === "landscapeHardcover") {
      return "Landscape Hardcover";
    } else if (book.bookType === "portraitSleeve") {
      return "Portrait Sleeve";
    } else if (book.bookType === "landscapeSleeve") {
      return "Landscape Sleeve";
    } else if (book.bookType === "pdfOnly") {
      return "PDF";
    }
    return null;
  };

  // calculates price
  const totalPrice = () => {
    return book.price * quantity;
  };

  /**
   * Onclick event handler
   * @param {onChange} event
   * @fires when quantity is changed
   * sets state
   */
  const handleQtyChange = (ev) => {
    ev.preventDefault();
    const newQuantity = parseInt(ev.target.value, 10);
    if (newQuantity > 0) {
      setQuantity(newQuantity); // Update the local state

      // Call handleEdit with the new quantity
      handleEdit(newQuantity);
    }
  };

  /**
   * Edit quantity in cart event handler
   * @param {onClick} event
   * @fires when update button is clicked
   * @dispatches an action to the Redux store that changes the orderQTY on the orderItem
   */
  const handleEdit = async (newQty) => {
    if (!id) {
      await dispatch(
        editItems({ bookId: book.id, orderQTY: Number(newQty), book })
      );
    } else {
      if (quantity > 0) {
        await dispatch(
          editOrderItems({ id, bookId: book.id, orderQTY: newQty })
        );
      } else {
        await dispatch(
          editOrderItems({
            id,
            bookId: book.id,
            orderQTY: newQty,
            orderId: null,
          })
        );
      }
    }
  };
  /**
   * Click event handler
   * @param {onClick} event
   * @fires when remove button is clicked
   * @dispatches an action to the Redux store to delete the puzzle from the order
   */
  const handleDelete = async () => {
    if (!id) {
      dispatch(editItems({ bookId: book.id, orderQTY: 0 }));
    } else {
      await dispatch(
        editOrderItems({
          id,
          bookId: book.id,
          orderQTY: quantity,
          orderId: null,
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.orderSection}>
        <p>Book Name</p>
        <h2 className={styles.name}>
          {book.storyName.length > 25
            ? `${book.storyName.substring(0, 25)}...`
            : book.storyName}
        </h2>
      </div>
      <div className={styles.orderSection}>
        <p>Book Type</p>
        <h2 className={styles.type}>{bookType()}</h2>
      </div>
      <div className={styles.orderSection}>
        <p>Book Price</p>
        <h2 className={styles.name}>${book.price}</h2>
      </div>
      <div className={styles.orderSection}>
        <p>QTY</p>
        <form className={styles.quantity}>
          <input
            name="quantity"
            type="number"
            value={quantity}
            onChange={handleQtyChange}
          />
        </form>
      </div>
      <div className={styles.orderSection}>
        <p>Subtotal</p>
        <h2>${totalPrice()}</h2>
      </div>
      <button onClick={handleDelete}>X</button>
    </div>
  );
};

export default OrderItemCard;
