import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AuthForm from "../components/auth/AuthForm";
import { me } from "./store";
import Home from "../components/home/Home";
import Gallery from "../components/gallery/Gallery"
import Create from "../components/create/Create"
import BookSelection from "../components/bookSelection/BookSelection";
import Cart from "../components/cart/Cart"
import Confirmation from "../components/checkout/Confirmation"
import UserAccount from "../components/user/UserAccount";
import AdminPage from "../components/admin/AdminPage";

const AppRoutes = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.me);
  const isAdmin = useSelector((state) => state.auth.me && state.auth.me.isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(me());
  }, [dispatch]);

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/create" element={<Create />} />
        <Route path="/book-selection" element={<BookSelection />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/cart/confirmation" element={<Confirmation />} />
        <Route path="/login" element={<AuthForm name="login" displayName="Login" />} />
        <Route path="/signup" element={<AuthForm name="signup" displayName="Sign Up" />} />
      </Routes>
    );
  }

  // If user is logged in
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/create" element={<Create />} />
      <Route path="/book-selection" element={<BookSelection />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/cart/confirmation" element={<Confirmation />} />
      <Route path="/users/:id" element={<UserAccount />} />
      {isAdmin && ( // Check if the user is an admin
        <>
          <Route path="/admin" element={<AdminPage />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;