import React, { useState } from 'react';
import styles from "../styles/Slideshow.module.css"

const Slideshow = ({ imageArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    const newIndex = (currentIndex - 1 + imageArray.length) % imageArray.length;
    setCurrentIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = (currentIndex + 1) % imageArray.length;
    setCurrentIndex(newIndex);
  };

  const currentImage = imageArray[currentIndex];

  return (
    <div className={styles.imageSlideshow}>
      <button onClick={handlePrevClick}>&#9664;</button>
      <img src={currentImage} alt={`Image ${currentIndex + 1}`} />
      <button onClick={handleNextClick}>&#9654;</button>
    </div>
  );
};

export default Slideshow;