import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
  
// Asynchronous thunk for editing the fulfillment status of an order
  export const editOrderFulfillment = createAsyncThunk("orders/fulfillment", async ({id, fulfilled}) => {
    try {
      const { data } = await axios.put(`/api/orders/${id}/fulfillment`, {
        fulfilled
      });
      return data;
    } catch (err) {
      console.log(err)
    }
  });
  
  export const orderSlice = createSlice({
    name: "order",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(editOrderFulfillment.fulfilled, (state, action) => {
        return action.payload;
      });
    },
  });
  
  export const selectOrder = (state) => state.order;
  
  export default orderSlice.reducer;
  