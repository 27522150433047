import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import styles from "../../styles/Create.module.css";

/*
  UploadImages component allows users to upload photos for their story.
  It handles file selection, uploads to the server, and navigation upon successful upload.
 */

const UploadImages = ({
  childName,
  childId,
  name,
  id,
  setClearLocalStorage,
}) => {
  const navigate = useNavigate();

  const [files, setFiles] = useState();
  const [uploadedImgUrls, setUploadedImgUrls] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  // Custom styles for the modal component
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 120,
    },
    overlay: {zIndex: 100}
  };

  // Effect to update localStorage with uploaded image URLs
  useEffect(() => {
    localStorage.setItem("uploadedImgUrls", JSON.stringify(uploadedImgUrls));
  }, [uploadedImgUrls]);

  // Functions to handle modal opening and closing
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Function to handle restart of the process
  //Clears localStorage so users can start their story from scratch
  const handleRestart = (e) => {
    e.preventDefault();
    setClearLocalStorage(true);
  };

  // Function to handle the submission of the upload form
  const submit = async (event) => {
    event.preventDefault();

  //The SDXL model training works best if we have at least 10 images
  //This sets a restriciton where users cannot continue if they don't upload at least 10 images
    if (!files || files.length < 10) {
      setErrorMessage("Please upload at least 10 images.");
      return;
    }

    setLoading(true);

    const formData = new FormData();

    if (typeof id === "string") {
      formData.append("guestId", id);
    } else {
      formData.append("userId", id);
    }

    if (childName) {
      formData.append("childName", childName);
    } else {
      formData.append("childName", name);
    }

    if (childId) {
      formData.append("childId", childId);
    }

    for (const file of files) {
      formData.append("image", file);
    }

    try {
      //uploads selected images to the AWS S3 bucket for storage
      const response = await axios.post("/api/awsS3/upload/images", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setFiles([]);
      setUploadedImgUrls(response.data);
      setUploadSuccess(true);
      setDisabled(false); // Set upload success to true
    } catch (error) {
      setErrorMessage("Upload failed:", error);
      setUploadSuccess(false); // Set upload success to false on error
    } finally {
      setLoading(false); // Set loading back to false after the upload is completed
    }
  };

  // Function to handle file selection change
  const handleFileChange = (e) => {
    // Convert the FileList object to an array and update the state
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setUploadSuccess(false);
  };

  return (
    <div className={styles.subContainerUser}>
      <div className={styles.chatContainer}>
        <section className={styles.uploadContainer}>
          <h2>
            Upload photos of {childName ? `${childName}` : `${name}`} here!
          </h2>
          <form onSubmit={submit}>
            <input
              className={styles.uploadInput}
              onChange={handleFileChange}
              type="file"
              accept="image/*"
              multiple // Allow multiple file selection
            ></input>
            <button type="submit" className={styles.uploadButton}>
              Submit
            </button>
          </form>
          {loading ? (
            <PropagateLoader
              color="#ffffff"
              loading={loading}
              className={styles.uploadLoader}
            />
          ) : null}
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
          {uploadSuccess && ( // Conditionally render success message
            <div className={styles.uploadSuccess}>
              <span className={styles.successMessage}>Upload success!</span>
              <span>
                <img
                  className={styles.successCheck}
                  src="/images/checkmark-green.png"
                />
              </span>
            </div>
          )}
          <p>
            Pro tip: to achieve the best results, we need between 10-20 photos
            of your child with varying expressions and no other subjects in the
            photo.
          </p>
          <p>
            We NEVER share your images with any third parties. Every child's
            uploaded images are deleted immediately after their book order is
            completed to protect their privacy.
          </p>
          <div className={styles.buttons}>
            <Link
              to={{
                pathname: "/book-selection",
                state: {
                  name: name,
                  childName: childName,
                  id: id,
                  uploadedImgUrls: uploadedImgUrls,
                },
              }}
            >
              <button
                className={
                  disabled
                    ? `${styles.button} ${styles.disabled}`
                    : `${styles.button}`
                }
                disabled={disabled}
              >
                Continue to Select Book Style
              </button>
            </Link>
            <button className={styles.restartButton} onClick={openModal}>
              Restart
            </button>
          </div>
          <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <section className={styles.modal}>
                <h2>Are you sure you want to start over?</h2>
                <p>This will erase all of your current story progress.</p>
                <div className={styles.buttons}>
                  <button className={styles.button} onClick={handleRestart}>
                    Yes, restart.
                  </button>
                  <button className={styles.button} onClick={closeModal}>
                    Nevermind!
                  </button>
                </div>
              </section>
            </Modal>
        </section>
        <img
          src="/images/user-owl-icons/user-icon.png"
          className={styles.chatIcon}
        />
      </div>
    </div>
  );
};

export default UploadImages;
