import React from "react";
import styles from "../../styles/Create.module.css";

/*
  StoryPrompt component provides an interface for users to input details for story generation.
  It allows users to choose between using predefined fields or writing a custom story prompt.
  The inputs are used as parameters for the ChatGPT4 API request to generate a story.
 */

const StoryPrompt = ({
  name,
  age,
  gender,
  numPages,
  interest,
  setNumPages,
  setAge,
  setGender,
  setName,
  setInterest,
  childAge,
  childGender,
  childName,
  childInterest,
  handleSubmit,
  setNextStep,
  showCreateAccountPrompt,
  setShowRenderPages,
  showRenderPages,
  extras,
  setExtras,
  addExtras,
  setAddExtras,
  useCustomPrompt,
  setUseCustomPrompt,
  customPrompt,
  setCustomPrompt,
}) => {
  return (
    <div>
      {/*Conditional rendering based on whether the user opts for a custom prompt or predefined inputs */}
      {useCustomPrompt ? (
        <div className={styles.subContainerUser}>
          <div className={styles.chatContainer}>
            <section className={styles.formContainer2}>
              <div className={styles.customPrompt}>
                <h2 className={styles.promptHeader}>Write Your Own Prompt</h2>
                <div>
                  {childName === "" && childAge === "" ? (
                    <div>
                      <div className={styles.promptOptions}>
                        <span>Write me a</span>
                        <select
                          value={numPages}
                          onChange={(e) => setNumPages(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                        </select>
                        <span>page story about a</span>
                        <input
                          className={styles.ageInput}
                          placeholder="age"
                          type="number"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                          key="age"
                        />
                        <span>year old</span>
                        <select
                          placeholder="gender"
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="girl">girl</option>
                          <option value="boy">boy</option>
                        </select>
                        <span>whose name is</span>
                        <input
                          className={styles.nameInput}
                          placeholder="First Name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          key="name"
                        />
                        <span>and in this story...</span>
                      </div>
                      <textarea
                        placeholder="a giant pink, polka-dotted monster jumps out of the bushes and they have to defeat it!"
                        type="text"
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        key="extras"
                        maxLength="400"
                      />
                      <p className={styles.maxLength}>
                        {customPrompt.length}/400
                      </p>
                      <p className={styles.hardcoverWarning}>
                        *If you would like to order a hardcover book, your story
                        must be 25 pages long.
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          setNextStep(true);
                        }}
                        className={
                          showCreateAccountPrompt
                            ? `${styles.promptButton} ${styles.disabled} `
                            : `${styles.promptButton}`
                        }
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className={styles.promptOptions}>
                        <span>Write me a</span>
                        <select
                          value={numPages}
                          onChange={(e) => setNumPages(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                        </select>
                        <span>page book about a</span>
                        <span>{childAge}</span>
                        <span>year old</span>
                        <span>{childGender}</span>
                        <span>whose name is</span>
                        <span>{childName}.</span>
                        <span>In this story...</span>
                      </div>
                      <textarea
                        placeholder="a giant pink, polka-dotted monster jumps out of the bushes and they have to defeat it!"
                        type="text"
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        key="extras"
                        maxLength="400"
                      />
                      <p className={styles.maxLength}>
                        {customPrompt.length}/400
                      </p>
                      <p className={styles.hardcoverWarning}>
                        *If you would like to order a hardcover book, your story
                        must be 25 pages long.
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          // setLoading(true);
                          handleSubmit();
                          setShowRenderPages(true);
                        }}
                        className={
                          showRenderPages
                            ? `${styles.promptButton} ${styles.disabled} `
                            : `${styles.promptButton}`
                        }
                      >
                        Write My Story
                      </button>
                    </div>
                  )}
                </div>
                <button
                  className={styles.switchButton}
                  onClick={() => setUseCustomPrompt(false)}
                >
                  Switch to Use Our Story Prompt Guide
                </button>
              </div>
            </section>
            <img
              src="/images/user-owl-icons/user-icon.png"
              className={styles.chatIcon}
            />
          </div>
        </div>
      ) : childName !== "" && childAge !== "" ? (
        <div className={styles.subContainerUser}>
          <div className={styles.chatContainer}>
            <section className={styles.formContainer2}>
              <form className={styles.storyPromptForm}>
                <h2 className={styles.promptHeader}>Story Prompt Guide</h2>
                <div>
                  <span>Write me a</span>
                  <select
                    value={numPages}
                    onChange={(e) => setNumPages(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                  </select>
                  <span>page book about a</span>
                  <span>{childAge}</span>
                  <span>year old</span>
                  <span>{childGender}</span>
                  <span>whose name is</span>
                  <span>{childName}</span>
                  <span>who absolutely loves</span>
                  <input
                    className={styles.favInput}
                    placeholder="favorite thing or activity"
                    type="text"
                    value={childInterest}
                    onChange={(e) => setInterest(e.target.value)}
                    key="interest"
                  />
                  <span>.</span>
                </div>
                <div className={styles.extras}>
                  <span>Anything else you'd like to add?</span>
                  <button
                    className={styles.extrasButton}
                    onClick={() => setAddExtras(!addExtras)}
                  >
                    {addExtras ? (
                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    ) : (
                      <span className="material-symbols-outlined">
                        expand_less
                      </span>
                    )}
                  </button>
                  {/* Conditional rendering based on whether a user would like to add extra details- a new textarea renders if so*/}
                  {addExtras ? (
                    <div>
                      <textarea
                        placeholder="Include a dinosaur named Bert who loves tacos."
                        type="text"
                        value={extras}
                        onChange={(e) => setExtras(e.target.value)}
                        key="extras"
                        maxLength="300"
                      />
                      <p className={styles.maxLength}>{extras.length}/300</p>
                    </div>
                  ) : null}
                </div>
                <p className={styles.hardcoverWarning}>
                  *If you would like to order a hardcover book, your story must
                  be 25 pages long.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    // setLoading(true);
                    handleSubmit();
                    setShowRenderPages(true);
                  }}
                  className={
                    showRenderPages
                      ? `${styles.promptButton} ${styles.disabled} `
                      : `${styles.promptButton}`
                  }
                >
                  Write My Story
                </button>
                <button
                  className={styles.switchButton}
                  onClick={() => setUseCustomPrompt(true)}
                >
                  Switch to Write a Custom Prompt
                </button>
              </form>
            </section>
            <img
              src="/images/user-owl-icons/user-icon.png"
              className={styles.chatIcon}
            />
          </div>
        </div>
      ) : (
        <div className={styles.subContainerUser}>
          <div className={styles.chatContainer}>
            <section className={styles.formContainer2}>
              <form className={styles.storyPromptForm}>
                <h2 className={styles.promptHeader}>Story Prompt Guide</h2>
                <div>
                  <span>Write me a</span>
                  <select
                    value={numPages}
                    onChange={(e) => setNumPages(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                  </select>
                  <span>page story about a</span>
                  <input
                    className={styles.ageInput}
                    placeholder="age"
                    type="number"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    key="age"
                  />
                  <span>year old</span>
                  <select
                    value={gender}
                    placeholder="gender"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="girl">girl</option>
                    <option value="boy">boy</option>
                  </select>
                  <span>whose name is</span>
                  <input
                    className={styles.nameInput}
                    placeholder="First Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    key="name"
                  />
                  <span>who absolutely loves</span>
                  <input
                    className={styles.favInput}
                    placeholder="favorite thing or activity"
                    type="text"
                    value={interest}
                    onChange={(e) => setInterest(e.target.value)}
                  />
                  <span>.</span>
                </div>
                <div className={styles.extras}>
                  <span>Anything else you'd like to add?</span>
                  <button
                    className={styles.extrasButton}
                    onClick={() => setAddExtras(!addExtras)}
                  >
                    {addExtras ? (
                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    ) : (
                      <span className="material-symbols-outlined">
                        expand_less
                      </span>
                    )}
                  </button>
                  {addExtras ? (
                    <div>
                      <textarea
                        placeholder="Include a dinosaur named Bert who loves tacos."
                        type="text"
                        value={extras}
                        onChange={(e) => setExtras(e.target.value)}
                        key="extras"
                        maxLength="300"
                      />
                      <p className={styles.maxLength}>{extras.length}/300</p>
                    </div>
                  ) : null}
                </div>
                <p className={styles.hardcoverWarning}>
                  *If you would like to order a hardcover book, your story must
                  be 25 pages long.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setNextStep(true);
                  }}
                  className={
                    showCreateAccountPrompt
                      ? `${styles.promptButton} ${styles.disabled} `
                      : `${styles.promptButton}`
                  }
                >
                  Next
                </button>
                <button
                  className={styles.switchButton}
                  onClick={() => setUseCustomPrompt(true)}
                >
                  Switch to Write a Custom Prompt
                </button>
              </form>
            </section>
            <img
              src="/images/user-owl-icons/user-icon.png"
              className={styles.chatIcon}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryPrompt;
