import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderItems,
  fetchOrderItems,
  selectOrderItem,
  clearItems,
  fetchItems,
  addOrderItems,
} from "../order/orderItemSlice";
import styles from "../styles/CartModal.module.css";
import OrderItemMiniCard from "../orderItem/OrderItemMiniCard";

const CartModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const cart = useSelector(selectOrderItem) || []; // Access the items array
  const userId = useSelector((state) => state.auth.me?.id);

    // Fetching cart contents on component mount or when userId changes
    //based on whether a user is logged in or not
  useEffect(() => {
    if (!userId) {
      dispatch(fetchItems()); // Fetch items for unauthenticated users
    } else {
      // Fetch items for authenticated users and sync local cart
      const localCart = JSON.parse(localStorage.getItem("order"));
      if (localCart && localCart.length) {
        //add all local items into user cart
        for (const item of localCart) {
          dispatch(
            addOrderItems({
              id: userId,
              bookId: item.bookId,
              orderQTY: item.orderQTY,
            })
          );
        }
        localStorage.removeItem("order"); // Clear local storage after syncing
        dispatch(fetchOrderItems(userId));
      } else {
        dispatch(fetchOrderItems(userId));
      }
    }
  }, [dispatch, userId]);

  //Function that updates ordertotal based on what elements are in the cart
  const orderTotal = () => {
    let prices = [];
    cart.forEach((orderItem) => {
      prices.push(Number(orderItem.book.price) * orderItem.orderQTY);
    });
    let total = 0.0;
    for (let i = 0; i < prices.length; i++) {
      total += prices[i];
    }
    return total;
  };

  //Function that estimates tax
  //THIS IS FOR MVP PURPOSES AND IS NOT ACCURATE TO LOCAL SALES TAX
  const estimateTax = () => {
    const tax = (orderTotal() / 100) * 10;
    return tax.toFixed(2);
  };
  //Function that calculates the complete total
  const total = () => {
    const total = Number(estimateTax()) + orderTotal();
    return total.toFixed(2);
  };

  // Clear cart event handler
  const handleClear = async () => {
    if (!userId) {
      dispatch(clearItems());
    } else {
      await dispatch(deleteOrderItems(userId));
    }
  };

  // Rendering cart contents
  const RenderCart = () => {
    if (!cart.length) {
      return (
        <div className={styles.emptyContainer} id="cart-modal">
          <h1 className={styles.cartHeaderEmpty}>Your cart is empty.</h1>
          <Link to="/create" onClick={closeModal}>
            <h2>Start creating your custom book now!</h2>
          </Link>
          <button className={styles.closeButton} onClick={closeModal}>
            Close
          </button>
        </div>
      );
    }
    return (
      <div className={styles.container} id="cart-modal">
        <div className={styles.top}>
          <h1 className={styles.cartHeader}>
            You have {cart.length} item(s) in your cart.
          </h1>
          <section className={styles.itemsContainer}>
            <section className={styles.items}>
              {cart.map((orderItem) => {
                return (
                  <OrderItemMiniCard
                    key={orderItem.bookId}
                    orderItem={orderItem}
                  />
                );
              })}
            </section>
            <div className={styles.totals}>
              <p>Estimated Tax: ${estimateTax()}</p>
              <p>
                <b>Order Total: ${total()}</b>
              </p>
            </div>
            <div className={styles.buttons}>
              <Link to="/cart" onClick={closeModal}>
                <button className={styles.button}>View Cart</button>
              </Link>
              <button className={styles.button} onClick={handleClear}>
                Clear Cart
              </button>
              <button className={styles.closeButton} onClick={closeModal}>
                Close
              </button>
            </div>
          </section>
        </div>
      </div>
    );
  };

  //JSX for the CartModal Component
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Cart Modal"
        className={styles.cartModal}
        overlayClassName={styles.overlay}
      >
        <RenderCart />
      </Modal>
    </div>
  );
};

export default CartModal;
