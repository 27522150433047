import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching a single user's data
export const fetchSingleUser = createAsyncThunk("singleUser", async (id) => {
  try {
    const { data } = await axios.get(`/api/users/${id}`);
    return data;
  } catch (err) {
    console.error(err);
  }
});

// Asynchronous thunk for editing a user's details
export const editUser = createAsyncThunk(
  "users/editUser",
  async ({ id, username, password, firstName, lastName, address }) => {
    try {
      const { data } = await axios.put(`/api/users/${id}/edit`, {
        username,
        password,
        firstName,
        lastName,
        address,
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

// Asynchronous thunk for editing a user's address
export const editUserAddress = createAsyncThunk(
  "users/editUser/address",
  async (addressData) => {
    try {
      const { data } = await axios.put(`/api/users/${addressData.id}/edit/address`, addressData);
      return data
    } catch (err) {
      console.log(err);
    }
  }
);

// Asynchronous thunk for creating or updating a user
export const createOrUpdateUser = createAsyncThunk(
  "users/createOrUpdateUser",
  async (userInfo) => {
    try {
      const { data } = await axios.put(
        `/api/users/${userInfo.email}`,
        userInfo
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

// Asynchronous thunk for changing a user's password
export const changeUserPassword = createAsyncThunk(
  "users/changeUserPassword",
  async ({ id, currentPassword, newPassword }) => {
    try {
      const { data } = await axios.put(`/api/users/${id}/change-password`, {
        currentPassword,
        newPassword,
      });
      return data;
    } catch (err) {
      throw err.response.data; // Throw the error response data to handle errors in your component
    }
  }
);

export const singleUserSlice = createSlice({
  name: "singleUser",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSingleUser.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(createOrUpdateUser.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(editUserAddress.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(changeUserPassword.fulfilled, (state, action) => {
      return action.payload;
    });
    // Handle errors for changeUserPassword
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      // You can handle errors here, e.g., store error message in the state
      state.passwordError = action.error.message;
    });
  },
});

export const selectSingleUser = (state) => {
  return state.singleUser;
};

export default singleUserSlice.reducer;
