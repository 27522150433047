import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleUser, selectSingleUser } from "../../user/userSlice";
import styles from "../../styles/Create.module.css";

/*ChildSelection component allows the user to select an existing child profile
or create a new one for story customization.

If a user selects a child profile, that child's information will be loaded into
the StoryPrompt inputs for easy story generation
*/

const ChildSelection = ({
  setChildAge,
  setChildGender,
  setChildName,
  setChildInterest,
  setChildId,
  setShowRenderStoryPrompt,
  setUseProfileParams,
  id,
}) => {
  const dispatch = useDispatch();

  const user = useSelector(selectSingleUser);

  // Fetch user data on component mount
  useEffect(() => {
    dispatch(fetchSingleUser(id));
  }, [dispatch, id]);

  // Conditionally render based on whether the user has child profiles
  if (user.children?.length > 0) {
    return (
      <div className={styles.subContainerOwl}>
        <img
          src="/images/user-owl-icons/owl-logo.png"
          className={styles.chatIcon}
        />
        <div className={styles.chatContainer}>
          <section className={styles.formContainer1}>
            <div className={styles.childSelection}>
              <div>
                <span>Select a child profile</span>
                <select
                  defaultValue=""
                  onChange={(e) => {
                    const selectedValues = e.target.value.split(",");
                    setChildAge(selectedValues[0]);
                    setChildGender(selectedValues[1]);
                    setChildName(selectedValues[2]);
                    setChildInterest(selectedValues[3]);
                    setChildId(selectedValues[4]);
                    setUseProfileParams(true);
                    setShowRenderStoryPrompt(true);
                  }}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {user.children?.map((child) => (
                    <option
                      key={child.id}
                      value={`${child.age},${child.gender},${child.name},${
                        child.interests ? child.interests[0] : ""
                      },${child.id}`}
                    >
                      {child.name}
                    </option>
                  ))}
                </select>
              </div>
              <span>or</span>
              <button
                className={styles.button}
                onClick={() => {
                  setChildAge("");
                  setShowRenderStoryPrompt(true);
                }}
              >
                Create a new one!
              </button>
            </div>
          </section>
        </div>
      </div>
    );
  }
// Render this section if there are no child profiles
  return (
    <div className={styles.subContainerOwl}>
      <img
        src="/images/user-owl-icons/owl-logo.png"
        className={styles.chatIcon}
      />
      <div className={styles.chatContainer}>
        <section className={styles.formContainer1}>
          <div>
            <span>It looks like you don't have any child profiles yet.</span>
            <button
              className={styles.button}
              onClick={() => setShowRenderStoryPrompt(true)}
            >
              Let's get started!
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ChildSelection;
