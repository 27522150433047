import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching all orders
export const fetchOrders = createAsyncThunk("fetchOrders", async () => {
  try {
    const { data } = await axios.get(`/api/orders`);
    return data;
  } catch (error) {
    console.error(error);
  }
});

// Asynchronous thunk for fetching past orders
export const fetchPastOrders = createAsyncThunk("fetchPastOrders", async () => {
  try {
    const { data } = await axios.get(`/api/orders/past`);
    return data;
  } catch (error) {
    console.error(error);
  }
});

// Asynchronous thunk for fetching active orders
export const fetchActiveOrders = createAsyncThunk(
  "fetchActiveOrders",
  async () => {
    try {
      const { data } = await axios.get(`/api/orders/active`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for deleting an order
export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async (id) => {
    try {
      const { data } = await axios.delete(`api/orders/${id}`);
      return data;
    } catch (error) {
      next(error);
    }
  }
);

// Slice for order management with an initial state set to an empty array
export const ordersSlice = createSlice({
  name: "orders",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      console.log("Fetched Orders: ", action.payload);
      return action.payload;
    });
    builder.addCase(fetchPastOrders.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchActiveOrders.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(deleteOrder.fulfilled, (state, { payload }) =>
      state.filter((a) => a.id !== payload.id)
    );
  },
});

export const selectOrders = (state) => state.orders;

export default ordersSlice.reducer;
