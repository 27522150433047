import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleUser, selectSingleUser } from "../../user/userSlice";
import styles from "../../styles/Create.module.css";

/*

NewAccountPrompt component displays a welcome message to the user
after creating a new account. It is passed the user's id from the Create page.
 
*/

const NewAccountPrompt = ({ id }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectSingleUser);

  // Fetches user data on component mount using the passed user ID
  useEffect(() => {
    dispatch(fetchSingleUser(id));
  }, [dispatch, id]);

  // Renders the welcome message to the new user
  return (
    <div className={styles.subContainerOwl}>
      <div className={styles.chatContainer}>
        <img
          src="/images/user-owl-icons/owl-logo.png"
          className={styles.chatIcon}
        />
        <section className={styles.formContainer1}>
          <span>Welcome, {user.firstName}! We're happy to have you!</span>
        </section>
      </div>
    </div>
  );
};

export default NewAccountPrompt;
