import React from "react";
import styles from "../styles/Footer.module.css"

const Footer = () => {
  return (
    <div className={styles.container}>
      <p><b>MyCustomStories | © 2023 IV U Enterprises LLC</b></p>
      <p>Need help? General inquiries? Send us a message at <b>info@mycustomstories.com</b></p>
    </div>
  )
};

export default Footer;