import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching page texts.
export const fetchPageTextsAsync = createAsyncThunk(
  "fetchPageTexts",
  async (id) => {
    try {
      const { data } = await axios.get(`/api/create/pagetext`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Asynchronous thunk for creating a new page text.
export const createPageTextsAsync = createAsyncThunk(
  "createPageTexts",
  async ({ pageNumber, s3Url, storyId }) => {
    const { data } = await axios.post("/api/create/pagetext/new", {
      pageNumber,
      s3Url,
      storyId
    });

    return data;
  })

// Asynchronous thunk for creating a new page text.
export const updatePageTextAsync = createAsyncThunk(
  "updatePageText",
  async ({ id, text }) => {
    const { data } = await axios.put(`/api/create/pagetext/edit/${id}`, {
      text
    });
    return data;
  }
);

// Asynchronous thunk for deleting a page text.
export const deletePageTextAsync = createAsyncThunk(
  "deletePageText", 
  async (id) => {
    const { data } = await axios.delete(`/api/create/pagetext/${id}`);
    return data;
  }
);

export const pageTextsSlice = createSlice({
  name: "pageTexts",
  initialState: [],
  reducers: {},
  extraReducers:(builder) => {
    builder.addCase(fetchPageTextsAsync.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(createPageTextsAsync.fulfilled, (state, action) => {
      return [...state, action.payload];
    });
    builder.addCase(updatePageTextAsync.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(deletePageTextAsync.fulfilled, (state, action) => {
      return {};
    });
  }
});

export const selectPageText = (state) => state.pageTexts;

export default pageTextsSlice.reducer;