import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSingleUser,
  selectSingleUser,
  editUser,
  editUserAddress,
} from "./userSlice";
import styles from "../styles/UserAccount.module.css";

//This component displays the user's profile information and is rendered on the main UserAccount page
//Allows users to edit their profile information

const UserInfo = ({ user }) => {
  const [edit, setEdit] = useState(false);
  const address = user.address;
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(user.firstName || "");
  const [lastName, setLastName] = useState(user.lastName || "");
  const [email, setEmail] = useState(user.email || "");
  const [birthdate, setBirthdate] = useState(user.birthdate || null);
  const [adrLine1, setAdrLine1] = useState("");
  const [adrLine2, setAdrLine2] = useState("");
  const [adrCity, setAdrCity] = useState("");
  const [adrState, setAdrState] = useState("");
  const [adrZip, setAdrZip] = useState("");
  const [isAgeValid, setIsAgeValid] = useState(true);

  // Handles the submission of the account information form
  const handleAccountInfoSubmit = async (e) => {
    e.preventDefault();
    await dispatch(editUser({ firstName, lastName, email, birthdate }));
    setEdit(false);
  };

  // Handles the submission of the address edit form
  const handleAddressEditSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      editUserAddress({
        line1: adrLine1,
        line2: adrLine2,
        city: adrCity,
        state: adrState,
        zip: adrZip,
      })
    );
    setEdit(false);
  };

  // Function to calculate the user's age
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    const age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthdate has occurred this year
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  };

  // Handles birthdate change and validates age
  const handleBirthdateChange = (e) => {
    const newBirthdate = e.target.value;
    setBirthdate(newBirthdate);

    // Calculate age and validate
    const age = calculateAge(newBirthdate);
    setIsAgeValid(age >= 13);
  };

  return (
    <div className={styles.userInfoContainer}>
      {edit ? (
        <div className={styles.editContainer}>
          <div>
            <h2 className={styles.userInfoHeading}>Edit Account Info</h2>
            <form
              className={styles.editForm}
              onSubmit={handleAccountInfoSubmit}
            >
              <div>
              <label htmlFor="firstName">First Name:</label>
              <input
                name="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="lastName">Last Name:</label>
              <input
                name="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="email">Email:</label>
              <input
                name="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="birthdate">Birthdate:</label>
              <input
                name="birthdate"
                type="date"
                value={birthdate}
                onChange={handleBirthdateChange}
                required
              />
              
              {isAgeValid ? null : (
                <p className="invalid">
                  You must be at least 13 years old to have an account.
                </p>
              )}
              </div>
              <div className={styles.buttons}>
                <button className={styles.userInfoButton} type="submit">
                  Save Changes
                </button>
                <button
                  className={styles.userInfoCancelButton}
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <div>
            <h2 className={styles.userShippingHeading}>
              Edit Default Shipping Address
            </h2>
            <form
              className={styles.editForm}
              onSubmit={handleAddressEditSubmit}
            >
              <div>
              <label htmlFor="adrLine1">Street Address:</label>
              <input
                name="adrLine1"
                type="text"
                value={adrLine1}
                onChange={(e) => setAdrLine1(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="adrLine2">Apt, Suite (Optional):</label>
              <input
                name="adrLine2"
                type="text"
                value={adrLine2}
                onChange={(e) => setAdrLine2(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="adrCity">City/Town:</label>
              <input
                name="adrCity"
                type="text"
                value={adrCity}
                onChange={(e) => setAdrCity(e.target.value)}
              />
              </div>
              <div>
              <label htmlFor="adrState">State</label>
              <select onChange={(e) => setAdrState(e.target.value)}>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              </div>
              <div>
              <label htmlFor="adrZip">Zip Code:</label>
              <input
                name="adrZip"
                type="text"
                value={adrZip}
                onChange={(e) => setAdrZip(e.target.value)}
              />
              </div>
              <div className={styles.buttons}>
                <button className={styles.userInfoButton} type="submit">
                  Save Changes
                </button>
                <button
                  className={styles.userInfoCancelButton}
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : user ? (
        <div>
          <div>
            <p className={styles.userInfoLabel}>First Name: {user.firstName}</p>
            <p className={styles.userInfoLabel}>Last Name: {user.lastName}</p>
            <p className={styles.userInfoLabel}>Email: {user.email}</p>
          </div>
          <div className={styles.userInfoAddress}>
            {address ? (
              <div>
                <p className={styles.userInfoLabel}>
                  Default Shipping Address:
                </p>
                <p>{address.line1}</p>
                {address.line2 && <p>{address.line2}</p>}
                <p>
                  {address.city}, {address.state} {address.postal_code}
                </p>
              </div>
            ) : (
              <button
                className={styles.userInfoAddAddressButton}
                type="button"
                onClick={() => setEdit(true)}
              >
                Add your default shipping address
              </button>
            )}
          </div>
          <button
            className={styles.userInfoButton}
            type="button"
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </div>
      ) : (
        <p>Oops! We couldn't find any user information.</p>
      )}
    </div>
  );
};

export default UserInfo;
