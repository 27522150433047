// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lMu0Cphz0aH4Pt3ORd6_ {
    display: flex;
    max-width: 22rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #f0f0f0;
  }
  
  .lMu0Cphz0aH4Pt3ORd6_ img {
    max-width: 100%;
    max-height: 400px; /* Adjust the max-height as needed */
    border: 1px solid #ddd;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
  }
  
  .lMu0Cphz0aH4Pt3ORd6_ button {
    background-color: rgba(92,225,230,1);
    color: #fff;
    border: none;
    padding: .5rem;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
  }
  
  .lMu0Cphz0aH4Pt3ORd6_ button:hover {
    background-color: #0056b3;
  }
  
  /* Disable prev button if on the first image */
  .lMu0Cphz0aH4Pt3ORd6_ button:first-child:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Disable next button if on the last image */
  .lMu0Cphz0aH4Pt3ORd6_ button:last-child:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }`, "",{"version":3,"sources":["webpack://./client/components/styles/Slideshow.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,iBAAiB,EAAE,oCAAoC;IACvD,sBAAsB;IACtB,0CAA0C;IAC1C,sCAAsC;EACxC;;EAEA;IACE,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,cAAc;IACd,eAAe;IACf,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,8CAA8C;EAC9C;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA,6CAA6C;EAC7C;IACE,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".imageSlideshow {\n    display: flex;\n    max-width: 22rem;\n    align-items: center;\n    justify-content: center;\n    padding: 1rem;\n    background-color: #f0f0f0;\n  }\n  \n  .imageSlideshow img {\n    max-width: 100%;\n    max-height: 400px; /* Adjust the max-height as needed */\n    border: 1px solid #ddd;\n    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);\n    transition: transform 0.3s ease-in-out;\n  }\n  \n  .imageSlideshow button {\n    background-color: rgba(92,225,230,1);\n    color: #fff;\n    border: none;\n    padding: .5rem;\n    cursor: pointer;\n    outline: none;\n    border-radius: 5px;\n  }\n  \n  .imageSlideshow button:hover {\n    background-color: #0056b3;\n  }\n  \n  /* Disable prev button if on the first image */\n  .imageSlideshow button:first-child:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n  \n  /* Disable next button if on the last image */\n  .imageSlideshow button:last-child:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageSlideshow": `lMu0Cphz0aH4Pt3ORd6_`
};
export default ___CSS_LOADER_EXPORT___;
