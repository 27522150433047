import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching all users from the database
export const fetchUsers = createAsyncThunk("allUsers", async () => {
  try {
    const { data } = await axios.get(`/api/users`);
    return data;
  } catch (err) {
    console.error(err);
  }
});

// Asynchronous thunk for adding a new user to the database
export const addUser = createAsyncThunk(
  "users/addUser",
  async ({ email, password, firstName, lastName, birthdate }) => {
    const { data } = await axios.post("/api/users/new", {
      email,
      password,
      birthdate,
      firstName,
      lastName,
    });
    return data;
  }
);

// Asynchronous thunk for deleting a user from the database
export const deleteUser = createAsyncThunk("users/deleteUser", async (id) => {
  try {
  const { data } = await axios.delete(`/api/users/${id}`);
  return data;
  } catch (err) {
    next (err)
  }
});

export const allUsersSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      //add users to state array
      return action.payload;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.push(action.payload);
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => 
    state.filter((a) => a.id !== payload.id));
  },
});

/**
 * selector function that allows us to access state by dispatching an action to the store
 * @param {[]} state user array
 * @returns the users stored in state
 */
export const selectUsers = (state) => state.users;

export default allUsersSlice.reducer;
