import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/Home.module.css";

//The homepage of the website

const Home = () => {
  const navigate = useNavigate();
  // const navigateToLogin = () => {
  //   navigate("/login");
  // };

  /* The following code is for the changing text in the second section of the homepage */

  // State for managing the index of the currently displayed word in the rotating text
  const [textIndex, setTextIndex] = useState(0);

  // List of words to be displayed in the rotating text sectio
  const words = [
    "CREATIVITY",
    "REPRESENTATION",
    "CONFIDENCE",
    "IMAGINATION",
    "INSPIRATION",
  ];

  // Effect to change the displayed word every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the text index to switch to the next word
      setTextIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.homeContainer}>
        <section className={styles.firstSection}>
          <div className={styles.leftBox}>
            <div className={styles.leftBoxText}>
              <h2>
                Custom stories <br /> written for your child, starring your
                child.
              </h2>
              <div>
                <p>
                  Let your child see themselves illustrated as the protagonist
                  of their own story, written just for them.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightPhoto}>
          </div>
          <div className={styles.bannerBottom}></div>
        </section>
        <section className={styles.secondSection}>
          <div className={styles.paint}>
            <span><h2>
              Give your child the gift of{" "}
            </h2></span>
            <span className={styles.changingWord}>{words[textIndex]}</span>
            <span>.</span>
          </div>
          <div className={styles.secondSectionButton}>
            <img src="/images/emph-left.png" className={styles.emphIcon} />
            <Link to="/create">
              <button>Get started</button>
            </Link>
            <img src="/images/emph-right.png" className={styles.emphIcon} />
          </div>
        </section>
        <section className={styles.sampleSection}>
          <div className={styles.sampleTopBanner}></div>
          <div className={styles.beforeAfterSection}>
            <div className={styles.beforeAfter}>
              <img src="/images/kid-model-pics.png" className={styles.mashaPics} />
            </div>
            <img src="/images/arrow.png" className={styles.arrow} />
            <div className={styles.beforeAfter}>
              <img src="/images/girl-books.png" className={styles.mashaBook} />
            </div>
          </div>
          <div className={styles.textButton}>
            <h2>Make your child the hero of their own story!</h2>
            <div className={styles.galleryButton}>
              <img src="/images/emph-left.png" className={styles.emphIcon} />
              <Link to="/gallery">
                <button>Check Out Our Gallery</button>
              </Link>
              <img src="/images/emph-right.png" className={styles.emphIcon} />
            </div>
          </div>
        </section>
        <section className={styles.thirdSection}>
          <img
            src="/images/howitworks-flag.png"
            className={styles.howItWorks}
          />
          <div className={styles.steps}>
            <div className={styles.step1}>
              <div className={styles.stepHeader}>
                <img src="/images/num1.png" className={styles.nums} />
                <h2>Share Your Child's Interests</h2>
              </div>
              <div className={styles.topRow}>
                <div className={styles.step}>
                  <img
                    src="/images/step1.png"
                    alt="Step one icon"
                    className={styles.icon}
                  />
                  <p>Share details about your child and their interests</p>
                </div>
                <div className={styles.step}>
                  <img
                    src="/images/step2.png"
                    alt="Step two icon"
                    className={styles.icon}
                  />
                  <p>We will write a custom story based on your input</p>
                </div>
                <div className={styles.step}>
                  <img
                    src="/images/step3.png"
                    alt="Step three icon"
                    className={styles.icon}
                  />
                  <p>Edit and confirm your story when you are satisfied</p>
                </div>
              </div>
            </div>
            <div className={styles.step2}>
              <div className={styles.stepHeader}>
                <img src="/images/num2.png" className={styles.nums} />
                <h2>Upload Photos</h2>
              </div>
              <div className={styles.middleRow}>
                <div className={styles.step}>
                  <img
                    src="/images/step4.png"
                    alt="Step four icon"
                    className={styles.icon}
                  />
                  <p>
                    Submit photos of your child to create custom illustrations
                    of them*
                  </p>
                </div>
                <div className={`${styles.step} ${styles.paletteIcon}`}>
                  <img src="/images/paint-palette.png" alt="Step six icon" />
                  <p>
                    We will create custom page illustrations for your story book
                    and send you a draft of the story within 2-5 business days
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.step3}>
              <div className={styles.stepHeader}>
                <img src="/images/num3.png" className={styles.nums} />
                <h2>We'll Take It From Here!</h2>
              </div>
              <div className={styles.bottomRow}>
                <div className={styles.step}>
                  <img
                    src="/images/step7.png"
                    alt="Step seven icon"
                    className={styles.icon}
                  />
                  <p>We will make any necessary edits based on your feedback</p>
                </div>
                <div className={styles.step}>
                  <img
                    src="/images/step8.png"
                    alt="Step eight icon"
                    className={styles.icon}
                  />
                  <p>
                    Your custom children's story will be shipped to your
                    doorstep and/or you will be emailed the PDF version of your
                    story
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.thirdSectionButton}>
            <img src="/images/emph-left.png" className={styles.emphIcon} />
            <Link to="/create">
              <button>Start Creating Your Book</button>
            </Link>
            <img src="/images/emph-right.png" className={styles.emphIcon} />
          </div>
        </section>
        <section className={styles.fourthSection}>
          <div className={styles.fourthSectionBanner}></div>
          <h1>MyCustomStories is the perfect gift for any occasion!</h1>
          <div className={styles.gifts}>
            <div className={styles.gift}>
              <img
                src="/images/section4/pexels-ekaterina-bolovtsova-4868645.jpg"
                alt="Mother and her son on his birthday"
                className={styles.pic}
              />
              <h2>Birthdays</h2>
            </div>
            <div className={styles.gift}>
              <img
                src="/images/section4/pexels-nicole-michalou-5774925.jpg"
                alt="Girl receiving present for a holiday"
                className={styles.pic}
              />
              <h2>Holidays</h2>
            </div>
            <div className={styles.gift}>
              <img
                src="/images/section4/pexels-yan-krukau-8612864.jpg"
                alt="Girl graduating preschool"
                className={styles.pic}
              />
              <h2>Moving Up a Grade</h2>
            </div>
            <div className={styles.gift}>
              <img
                src="/images/section4/pexels-timur-weber-9127698.jpg"
                alt="Father and son reading"
                className={styles.pic}
              />
              <div className={styles.confText}>
                <h2>
                  To Inspire Confidence
                  <br />
                  and a Love of Reading
                </h2>
              </div>
            </div>
          </div>
        </section>
      </div>
   </section>
  );
};

export default Home;
