// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AFkcLYmJUejJ8bWmuU2Z {
  width: 98%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px grey solid;
  padding: 1rem;
  font-size: 0.75rem;
  background-color: white;
  border-top: 1px solid black;
  border-top: 1px solid black;
  margin: .5rem 0 .5rem 0;
  gap: 1rem;
}

.AFkcLYmJUejJ8bWmuU2Z h2 {
    font-size: .8rem;
}

.AFkcLYmJUejJ8bWmuU2Z input {
  width: 1.75rem;
  height: 1.75rem;
}

.Mx5Aat89tJFT6EfWeOLl {
    margin-top: 1rem;
    border: none;
    background-color: rgba(92,225,230,1);
    border-radius: 5px;
    padding: .3rem .5rem .3rem .5rem;
    cursor: pointer;
    margin-bottom: .6rem;
  }`, "",{"version":3,"sources":["webpack://./client/components/styles/OrderItemMiniCard.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,0BAA0B;EAC1B,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,2BAA2B;EAC3B,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;AACX;;AAEA;IACI,gBAAgB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;IACf,oBAAoB;EACtB","sourcesContent":[".container {\n  width: 98%;\n  height: 4rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  border-top: 1px grey solid;\n  padding: 1rem;\n  font-size: 0.75rem;\n  background-color: white;\n  border-top: 1px solid black;\n  border-top: 1px solid black;\n  margin: .5rem 0 .5rem 0;\n  gap: 1rem;\n}\n\n.container h2 {\n    font-size: .8rem;\n}\n\n.container input {\n  width: 1.75rem;\n  height: 1.75rem;\n}\n\n.xButton {\n    margin-top: 1rem;\n    border: none;\n    background-color: rgba(92,225,230,1);\n    border-radius: 5px;\n    padding: .3rem .5rem .3rem .5rem;\n    cursor: pointer;\n    margin-bottom: .6rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AFkcLYmJUejJ8bWmuU2Z`,
	"xButton": `Mx5Aat89tJFT6EfWeOLl`
};
export default ___CSS_LOADER_EXPORT___;
