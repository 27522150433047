import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPassword,
  fetchSingleUser,
  selectSingleUser,
} from "./userSlice";
import styles from "../styles/UserAccount.module.css"

//This component allows users to change their password

const UserPassword = () => {
  const userId = useSelector((state) => state.auth.me?.id);
  const user = useSelector(selectSingleUser);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePass, setChangePass] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch user data when the component mounts
    if (userId) {
      dispatch(fetchSingleUser(userId))
        .then(() => {
          // Handle password-related errors if any (e.g., password change failed)
          if (user.passwordError) {
            setPasswordError(user.passwordError);
          } else {
            setPasswordError(""); // Clear password error
          }
        });
    }
  }, [dispatch, userId, user.passwordError]);

   const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Check if new password matches the confirmation
    if (newPassword !== confirmPassword) {
      // Handle password mismatch error (display error message or UI feedback)
      setPasswordError("Passwords do not match");
      return;
    }

    // Dispatch an action to change the password
    await dispatch(changeUserPassword({ id: userId, currentPassword, newPassword }));

    // Clear the password fields and set success message
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setChangePass(false);
    setSuccessMessage(true);

    // Clear success message after 5 seconds
    setTimeout(() => {
      setSuccessMessage(false);
    }, 5000); // Adjust the time as needed
  };

  return (
    <div>
      {changePass ? (
        <div>
          <h2>Change Password</h2>
          <form className={styles.editForm} onSubmit={handlePasswordChange}>
            <label htmlFor="currentPassword">Current Password:</label>
            <input
              name="currentPassword"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
            <label htmlFor="newPassword">New Password:</label>
            <input
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <label htmlFor="confirmPassword">Confirm New Password:</label>
            <input
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {passwordError && <p className="error">{passwordError}</p>}
            <button className={styles.userInfoButton} type="submit">Change Password</button>
          </form>
        </div>
      ) : (
        <div>
          {successMessage ? <h2>Password successfully changed.</h2> : null}
          <button className={styles.userInfoButton}
            onClick={() => {
              setChangePass(true);
              setSuccessMessage(false);
            }}
          >
            Change Password
          </button>
        </div>
      )}
    </div>
  );
};

export default UserPassword;
