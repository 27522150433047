import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching the current user's data.
export const me = createAsyncThunk("auth/me", async (_, thunkAPI) => {
  try {
    const res = await axios.get("/auth/me");
    if (res.data.loggedIn) {
      return res.data.user ;
    }
    return null;
  } catch (err) {
    if (err.response.data) {
      return thunkAPI.rejectWithValue(err.response.data);
    } else {
      return "There was an issue with your request.";
    }
  }
});

// Asynchronous thunk for user login.
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      await axios.post("/auth/login", { email, password });
      const meResponse = await thunkAPI.dispatch(me());
      return { loggedIn: true, me: meResponse.payload }; // Include the result of me() in the payload
    } catch (error) {
      return { error: error.response.data };
    }
  }
);

// Asynchronous thunk for user signup.
export const signup = createAsyncThunk(
  "auth/signup",
  async (userData, thunkAPI) => {
    try {
      await axios.post("/auth/signup", userData);
      const meResponse = await thunkAPI.dispatch(me()); // Dispatch me() before returning
      return { loggedIn: true, me: meResponse.payload };
    } catch (error) {
      return { error: error.response.data };
    }
  }
);

// Asynchronous thunk for user logout.
export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    await axios.post("/auth/logout");
    return { loggedIn: false, me: null };
  } catch (error) {
    return { error: error.response.data };
  }
});

// Auth slice for managing authentication state.
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    me: null, // Current user object.
    loggedIn: false, // Login status.
    error: null, // Error state.
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(me.fulfilled, (state, action) => {
      state.me = action.payload;
    });
    builder.addCase(me.rejected, (state, action) => {
      state.error = action.error;
    });
    builder.addCase(login.fulfilled, (state) => {
      state.loggedIn = true;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loggedIn = false;
      state.error = action.payload.error;
    });
    builder.addCase(signup.fulfilled, (state) => {
      state.loggedIn = true;
      state.error = null;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loggedIn = false;
      state.error = action.payload.error;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.me = null;
      state.loggedIn = false;
      state.error = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loggedIn = false;
      state.error = action.payload.error;
    });
  },
});

// Export the authSlice actions
export const selectUserObject = (state) => {
  return state.auth.me;
};

// Export the authSlice reducer
export default authSlice.reducer;
