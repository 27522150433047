import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for fetching details of a single child based on ID
export const fetchChild = createAsyncThunk("child", async (id) => {
    try {
        const { data } = await axios.get(`/api/children/${id}`);
        return data;
    } catch (err) {
        console.error(err);
    }
});

// Asynchronous thunk for editing a child's details
export const editChild = createAsyncThunk("child/editChild", async ({ id, name, age, birthday, gender, interests }, { rejectWithValue }) => {
  try {
    const response = await axios.put(`/api/children/${id}/edit`, {
      name,
      age,
      birthday,
      gender,
      interests,
    });

    // Return the updated child data
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made, but the server responded with an error status code
      return rejectWithValue(error.response.data);
    } else if (error.request) {
      // The request was made, but no response was received
      return rejectWithValue('No response received from the server.');
    } else {
      // Something else went wrong
      return rejectWithValue('An error occurred while updating the child.');
    }
  }
});


// Slice for a single child with its initial state set to an empty object
  export const childSlice = createSlice({
    name: "child",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(fetchChild.fulfilled, (state, action) => {
        return action.payload;
      });
      builder.addCase(editChild.fulfilled, (state, action) => {
        return action.payload;
      });
    },
  });

  export const selectChild = (state) => {
    return state.child;
  };
  
  export default childSlice.reducer;