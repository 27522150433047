import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for adding a new email.
export const addEmail = createAsyncThunk(
    "email/addEmail",
    async ({ email }) => {
      const { data } = await axios.post("/api/users/newEmail", {
        email
      });
      return data;
    }
  );

  // Slice for handling email state in the Redux store.
  export const emailSlice = createSlice({
    name: "email",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(addEmail.fulfilled, (state, action) => {
        state.push(action.payload);
      });
    },
  });

export default emailSlice.reducer;