import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders, selectOrders } from "../../order/ordersSlice";
import OrderCard from "./OrderCard";
import styles from "../../styles/Admin.module.css";

// AllOrders: A React component that renders a list of all orders.
// It fetches orders from the Redux store and displays each order using the OrderCard component.

const AllOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders); // Retrieves the list of orders from the Redux store.
  const [showOrders, setShowOrders] = useState(false); // State to toggle the display of the orders.

  // useEffect to fetch orders when the component mounts.
  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  // JSX rendering for the AllOrders component.
  // Includes a toggle button to show/hide orders and a map function to render each order using OrderCard.
  return (
    <section className={styles.componentContainer}>
      <div className={styles.componentHeader}>
        <h2>All Orders</h2>
        <button
          className={styles.booksButton}
          onClick={() => setShowOrders(!showOrders)}
        >
          {showOrders ? (
            <span className="material-symbols-outlined">expand_more</span>
          ) : (
            <span className="material-symbols-outlined">expand_less</span>
          )}
        </button>
      </div>
      {showOrders ? (
        <div>
          {orders.map((order) => {
            return <OrderCard key={order.id} id={order.id} order={order} />;
          })}
        </div>
      ) : null}
    </section>
  );
};

export default AllOrders;
