import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//Asynchronous thunk for creating a new story.
export const createStoryAsync = createAsyncThunk(
  "createStory",
  async ({ name, userId, childId, guestId, childName }) => {
    const { data } = await axios.post("/api/create/story/new", {
      name,
      userId,
      childId,
      guestId,
      childName
    });
    return data;
  }
);

// Asynchronous thunk for deleting an existing story.
export const deleteStoryAsync = createAsyncThunk("deleteStory", async (id) => {
  const { data } = await axios.delete(`/api/create/story/${id}`);
  return data;
});

// Slice for handling stories state in the Redux store.
export const storiesSlice = createSlice({
  name: "stories",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createStoryAsync.fulfilled, (state, action) => {
      return [...state, action.payload];
    });
    builder.addCase(deleteStoryAsync.fulfilled, (state, action) => {
      return {};
    });
  },
});

export const selectStories = (state) => {
  return state.stories;
};

export default storiesSlice.reducer;
