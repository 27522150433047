import React from "react";
import { useDispatch } from "react-redux";
import { addChild } from "../../child/childrenSlice";
import styles from "../../styles/Create.module.css";

//ChildProfilePrompt component is responsible for creating a child profile.
//It receives several props from its parent component for managing state.

const ChildProfilePrompt = ({
  name,
  gender,
  age,
  interest,
  handleSubmit,
  setChildId,
  id,
  setShowRenderPages,
  setLoading,
}) => {
  const dispatch = useDispatch();

  // /handleCreateChildProfile handles the creation of a child profile.
  const handleCreateChildProfile = async (e) => {
    const child = await dispatch(
      addChild({
        name: name,
        gender: gender,
        age: age,
        interests: [interest],
        userId: id,
      })
    );

    const childId = child.payload.id;
    setChildId(childId);

    setLoading(true);
    handleSubmit();
    setShowRenderPages(true);
  };

  return (
    <div className={styles.subContainerOwl}>
      <div className={styles.chatContainer}>
        <img
          src="/images/user-owl-icons/owl-logo.png"
          className={styles.chatIcon}
        />
        <section className={styles.formContainer1}>
          <div className={styles.childProfilePrompt}>
            <span>
              Do you want to create a child profile for {name}? This will make
              writing {gender === "girl" ? "her" : "his"} next book a breeze!
            </span>
            <div className={styles.buttons}>
              <button
                onClick={handleCreateChildProfile}
                className={styles.button}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setLoading(true);
                  handleSubmit();
                  setShowRenderPages(true);
                }}
                className={styles.button}
              >
                No
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ChildProfilePrompt;
