import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders, selectOrders } from "../../order/ordersSlice";
import OrderCard from "./OrderCard";
import styles from "../../styles/Admin.module.css";

// ActiveOrders: A React component to display active (unfulfilled) orders.
// It filters orders based on the 'fulfilled' attribute and renders each using the OrderCard component.
const ActiveOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders); // Retrieves the list of orders from the Redux store.
  const [showOrders, setShowOrders] = useState(false); // State to toggle the display of the orders.

    // useEffect to fetch orders when the component mounts.
  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  // Filters orders based on fulfillment attribute being false
  const unfulfilledOrders = orders.filter((order) => !order.fulfilled);

  // JSX rendering for the ActiveOrders component.
  // Includes a toggle button to show/hide orders and maps unfulfilled orders to OrderCard components.
  return (
    <section className={styles.componentContainer}>
      <div className={styles.componentHeader}>
        <h2>Active Orders</h2>
        <button
          className={styles.booksButton}
          onClick={() => setShowOrders(!showOrders)}
        >
          {showOrders ? (
            <span className="material-symbols-outlined">expand_more</span>
          ) : (
            <span className="material-symbols-outlined">expand_less</span>
          )}
        </button>
      </div>
      {showOrders ? (
        <div>
          {unfulfilledOrders.map((order) => {
            return <OrderCard key={order.id} id={order.id} order={order} />;
          })}
        </div>
      ) : null}
    </section>
  );
};

export default ActiveOrders;
