import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/OrderItemMiniCard.module.css";
import { editOrderItems, editItems } from "../order/orderItemSlice";

// Component to render a mini card for an order item for the cart modal

const OrderItemMiniCard = ({ orderItem }) => {
  const book = orderItem.book;
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(0);
  const id = useSelector((state) => state.auth.me?.id);

  // Effect hook to set the quantity when the order item changes
  useEffect(() => {
    setQuantity(orderItem.orderQTY);
  }, [orderItem]);

  // Function to determine the type of book
  const bookType = () => {
    if (book.bookType === "portraitPaperback") {
      return "Portrait Paperback"
    } else if (book.bookType === "portraitHardcover") {
      return "Portrait Hardcover"
    } else if (book.bookType === "landscapeHardcover") {
      return "Landscape Hardcover"
    } else if (book.bookType === "portraitSleeve") {
      return "Portrait Sleeve"
    } else if (book.bookType === "landscapeSleeve") {
      return "Landscape Sleeve"
    } else if (book.bookType === "pdfOnly") {
      return "PDF"
    } return null
  };

  // Handler function for deleting an order item
  const handleDelete = async () => {
    if (!id) {
      // Handle deletion for users not logged in
      dispatch(editItems({ bookId: book.id, orderQTY: 0 }));
    } else {
      // Handle deletion for logged-in users
      await dispatch(
        editOrderItems({
          id,
          bookId: book.id,
          orderQTY: quantity,
          orderId: null,
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftText}>
        <h2 className={styles.name}>{book.storyName}</h2>
      </div>
      <h2>{bookType()}</h2>
      <h2>${book.price}</h2>
      <button className={styles.xButton} onClick={handleDelete}>x</button>
    </div>
  );
};

export default OrderItemMiniCard;
